import AsyncStorage from '@react-native-community/async-storage';
// import { AllFilters } from "../Filters";
import createEncryptor from 'redux-persist-transform-encrypt';
import createPersistPlugin from '@rematch/persist';

const encryptor = createEncryptor({
  secretKey: '977a815e-c711-4056-b8bb-489112e0fffa',
  onError: function (error) {
    // Handle the error.
    console.log('error =-=>', error);
  }
});

export const persistPlugin = createPersistPlugin({
  key: 'root',
  whitelist: ['Auth', 'Invoice', 'Property', 'Timeline'],
  // blacklist: blackList,
  // throttle: 3000,
  version: 1,
  storage: AsyncStorage,
  transforms: [
    encryptor
    // AllFilters,
  ]
});
