import React, { useRef, useState } from 'react';
import SignUpSidebar from '../../components/SignUpSidebar/SignUpSidebar';
import { Toast } from 'primereact/toast';
import VerifyForgetPassword from '../../components/VerifyForgotPassword/VerifyForgotPassword';
import VerifyEmail from '../../components/VerifyEmail/VerifyEmail';

const VerifyForgotPass = () => {
  const toast = useRef(null);

  return (
    <div>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <div className="grid align-items-center">
          <div className="col-12 md:col-6 xl:col-4">
            <SignUpSidebar />
          </div>
          <div className="col-12 md:col-6 xl:col-8">
            <VerifyForgetPassword toast={toast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyForgotPass;
