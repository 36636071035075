import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HSInput } from '../../stories/Input/Input';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import './MainCard.scss';
import { HSButton } from '../../stories/Button/Button';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from '../../hooks/useAuth';
import { formatToUsCurrency, numOfPropertyInPage } from '../../utils/Common/Constant';

const MainCard = (props: any) => {
  const navigate = useNavigate();
  const property = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();
  const [lists, setList] = useState([]);
  const { logOut } = useAuth();

  useEffect(() => {
    let _status = 'Open';
    if (property.filter.open && property.filter.closed) {
      _status = 'Both';
    } else if (property.filter.open) {
      _status = 'Open';
    } else if (property.filter.closed) {
      _status = 'Closed';
    } else {
      _status = 'Open';
      setTimeout(() => {
        dispatch.Property.updatePropertyFilter({
          open: true
        });
      }, 100);
    }

    let payload: any = {
      limit: numOfPropertyInPage,
      page: property.filter.page,
      status: _status,
      sortFields: [
        {
          fieldName: 'createdAt',
          orderDirection: 'DESC'
        }
      ]
    };
    if (property.filter.search.trim().length > 0) {
      payload['search'] = property.filter.search;
    }
    if (!loadingState.effects.Property.fetchProperty)
      dispatch.Property.fetchProperty(payload, (res: any) => {
        if (res?.statusCode === 401) {
          logOut(props.toast);
        } else {
          if (res.success) {
          } else {
            props.toast.current.show({
              severity: 'error',
              summary: 'Error Message',
              detail: res.message,
              life: 3000
            });
          }
        }
      });
  }, [property.filter]);

  return (
    <>
      <div className="mainCard">
        <div className="grid cardWrap">
          {property?.propertyList.length > 0 &&
            property?.propertyList?.map((propertyItem: any, inx: any) => {
              // {console.log(propertyItem,"<<<<<<<<<<<<<<<<<<<<<<<<propertyItem")}
              return (
                <div
                  className="col-12 lg:col-4 md:col-6 cardBox"
                  key={inx}
                  onClick={() => navigate('/timeline-view')}>
                  <div>
                    <Card>
                      <div className="cardImg">
                        <img src={propertyItem.photo} alt={''} />
                        {/* <div className="cardCaption">Closing day</div> */}
                      </div>
                      <div className="priceWrap">
                        <div className="price">{formatToUsCurrency(propertyItem.price) || 0}</div>
                        <div className="userGroup">
                          {propertyItem?.invitees && (
                            <AvatarGroup>
                              {propertyItem.invitees?.map((item: any) => {
                                return !item.photo ? (
                                  <Avatar label={item.initials} shape="circle" />
                                ) : (
                                  <Avatar image={item.photo} shape="circle" />
                                );
                              })}
                              {propertyItem.invitees && propertyItem.invitees.length > 3 && (
                                <Avatar
                                  label={(propertyItem.invitees.length - 3).toString()}
                                  shape="circle"
                                  size="large"
                                  style={{ backgroundColor: '#1C1F25', color: '#ffffff' }}
                                />
                              )}
                            </AvatarGroup>
                          )}
                        </div>
                      </div>
                      <div className="address">
                        {propertyItem.street}
                        <span>
                          {propertyItem.city},{propertyItem.state}
                        </span>
                      </div>
                      <div className="viewAll">
                        <HSButton label={'View property detail'} />
                      </div>
                    </Card>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MainCard;
