import React, { useEffect, useImperativeHandle } from 'react';
import { useState } from 'react';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import { HSInput } from '../../stories/Input/Input';
import { HSDropdown } from '../../stories/SelectDropdown/SelectDropdown';
import { useNavigate } from 'react-router-dom';
import './Invite1.scss';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import * as Constant from '../../utils/Common/Constant';
import { Trash } from 'iconsax-react';

const Invite1 = (props: any) => {
  const { formik, childRef } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const auth = useSelector((state: any) => state.Auth);
  const InviteModel = useSelector((state: any) => state.Invite);
  const propertyModel = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  const [selectUserRoles, setSelectUserRoles] = useState(InviteModel.userRoles || []);
  const [selectUserTypes, setSelectUserTypes] = useState(InviteModel.userTypes || []);

  useEffect(() => {
    // Get UserRolesonChangeEmailValidate
    // Get UserTypes
    // dispatch.Invite.userTypes((res: any) => {
    //   if (res.success) {
    //     let types = res.data.map((ele: any) => {
    //       return { label: ele.name, value: ele };
    //     });
    //     setSelectUserTypes(types);
    //   } else {
    //     props.toast.current.show({
    //       severity: 'error',
    //       summary: 'Error Message',
    //       detail: res.message,
    //       life: 3000
    //     });
    //   }
    // });
  });

  const [teamData, setTeamData] = useState([
    {
      email: '',
      type: {}, // Object.getAllKeys(item.type).lenght> 0
      role: {},
      emailError: '',
      typesError: '',
      rolesError: ''
    }
  ]);
  const handleOnchangeEvent = (index: number, e: any, key: string) => {
    let data: any = [...teamData];
    data[index][key] = e.target.value;
    setTeamData(data);
  };
  const handleOnBlurEmail = (index: number, e: any, key: string) => {
    console.log('~~~~~~~~~~~~~~~~~~~~~~>', e.target.value);
    let isValidEmail = Constant.isValidEmail(e.target.value);
    console.log('isValidEmail', isValidEmail);
    if (isValidEmail) {
      let data: any = [...teamData];
      data[index]['emailError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['emailError'] = 'Please enter correct email';
      setTeamData(data);
    }
  };

  const handleOnBlurDropdown = (index: number, e: any, key: string) => {
    let dataValue = e.value;
    let data: any = [...teamData];
    console.log(e, data);
    if (dataValue) {
      data[index]['typesError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['typesError'] = 'Required';
      setTeamData(data);
    }
  };

  const handleOnBlurDropdownRole = (index: number, e: any, key: string) => {
    let dataValue = e.value;
    let data: any = [...teamData];
    console.log(e, data);
    if (dataValue) {
      data[index]['rolesError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['rolesError'] = 'Required';
      setTeamData(data);
    }
  };
  const handleTeamAdd = () => {
    setTeamData([
      ...teamData,
      {
        email: '',
        type: {},
        role: {},
        emailError: '',
        typesError: '',
        rolesError: ''
      }
    ]);
  };
  const handleTeamRemove = (index: any) => {
    const data = teamData.filter((i, inx) => inx !== index);
    setTeamData(data);
  };

  const handleCheckError = () => {
    let hasError = false;
    let _teamData: any = teamData;
    _teamData = _teamData.map((item: any, idx: any) => {
      if (item.email === '') {
        item.emailError = 'Enter valid email *';
        hasError = true;
      }
      if (Object.keys(item.role).length === 0) {
        item.rolesError = 'Required';
        hasError = true;
      }
      if (Object.keys(item.type).length === 0) {
        item.typesError = 'Required';
        hasError = true;
      }
      console.log(_teamData);

      return {
        ...item,
        typeId: item?.type?.typeID,
        roleId: item?.role?.roleID
      };
    });
    setTeamData([..._teamData]);

    return hasError;
  };

  useImperativeHandle(childRef, () => ({
    submit() {
      // handleCheckError();
      return teamData;
    }
  }));

  return (
    <>
      <div className="inviteBlock">
        <div className="inviteBox">
          <div className="memberBox">
            <h1>3: Invite Team Members</h1>
            <div className="labelWrap">
              <label>Email Address</label>
              <label>User Type</label>
              <label>User Role</label>
              <label></label>
            </div>
            <div className="userWrap">
              {teamData?.map((item, index) => {
                return (
                  <div className="inviteUser" key={index}>
                    <div className="inputWrap" key="index">
                      <div className="inputBx">
                        <HSInput
                          type="email"
                          placeholder={'john@email.com'}
                          name={'email'}
                          onChange={(e: any) => handleOnchangeEvent(index, e, 'email')}
                          onBlur={(e: any) => handleOnBlurEmail(index, e, 'error')}
                          value={item.email}
                          id="id1"
                        />
                        {item.emailError.length > 0 && (
                          <p className="errorMessage">Enter valid email</p>
                        )}
                      </div>
                      <div className="dropDownWrap">
                        <HSDropdown
                          onChange={(e: any) => handleOnchangeEvent(index, e, 'type')}
                          value={item.type}
                          name={'type'}
                          onBlur={(e: any) => handleOnBlurDropdown(index, e, 'error')}
                          options={selectUserTypes}
                          id={'id3'}
                        />
                        {item.typesError.length > 0 && <p className="errorMessage">Required *</p>}
                      </div>

                      <div className="dropDownWrap">
                        <HSDropdown
                          onChange={(e: any) => handleOnchangeEvent(index, e, 'role')}
                          value={item.role}
                          name={'role'}
                          onBlur={(e: any) => handleOnBlurDropdownRole(index, e, 'error')}
                          options={selectUserRoles}
                          id={'id2'}
                        />
                        {item.rolesError.length > 0 && <p className="errorMessage">Required *</p>}
                      </div>
                      <div className="wrapBtns">
                        <div className="deleteBtn">
                          {(index < teamData.length - 1 ||
                            (index === teamData.length - 1 && teamData.length > 1)) && (
                            <div className="delUser" onClick={() => handleTeamRemove(index)}>
                              <Trash size="22" color="#FF4848" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="addUser" onClick={handleTeamAdd} tabIndex={4}>
              + Add New User
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Invite1;
