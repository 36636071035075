import React from 'react';
import { InputText } from 'primereact/inputtext';
import './Input.scss';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import OtpInput from 'react-otp-input';

export const HSInput = (props: any) => {
  return (
    <div className="inputBox position-relative">
      <label>{props.label}</label>
      <InputText
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        onBlur={props.onBlur}
        autoComplete="off"
        disabled={props.disabled || false}
      />
      {props.isError && (
        <small id="username2-help " className="p-error block pt-2 showError errorMessage">
          {props.errorMessage}
        </small>
      )}
    </div>
  );
};
export const HSInputPassword = (props: any) => {
  return (
    <div className="inputBox position-relative">
      <label>{props.label}</label>
      <Password
        placeholder={props.placeholder}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        feedback={false}
        onBlur={props.onBlur}
        toggleMask
      />
      {props.isError && (
        <small id="username2-help" className="p-error block pt-2 showError errorMessage">
          {props.errorMessage}
        </small>
      )}
    </div>
  );
};
export const HSCalendar = (props: any) => {
  return (
    <div className="inputBox">
      <label>{props.label}</label>
      <Calendar
        id="basic"
        value={props.value}
        onChange={props.onChange}
        dateFormat="mm-dd-yy"
        showIcon
      />
    </div>
  );
};
export const HSInputMaskNumber = (props: any) => {
  return (
    <div className="inputBox ">
      <label>{props.label}</label>
      <InputMask
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        mask="999-999-9999"
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        className={props.isError ? 'showBorder' : ''}
      />
      {props.isError && (
        <small id="username2-help" className="p-error block pt-2  showError errorMessage">
          {props.errorMessage}
        </small>
      )}
    </div>
  );
};
export const HSInputOtp = (props: any) => {
  return (
    <div className="inputBox justify-content-center flex inputOtpBox">
      <OtpInput
        onChange={props.onChange}
        value={props.value}
        className="otptext "
        numInputs={6}
        // name={props.name}
        isInputNum={true}
        hasErrored={props.isError}
      />
      {props.isError && (
        <small id="username2-help" className="p-error block showError-otp showError errorMessage">
          Verification code is invalid
        </small>
      )}
    </div>
  );
};
export const HSInputV2 = (props: any) => {
  return (
    <div className="inputBox position-relative">
      <label>{props.label}</label>
      <InputText
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        onBlur={props.onBlur}
      />
      {props.isError && (
        <small id="username2-help " className="p-error block pt-2 showError errorMessage">
          {props.errorMessage}
        </small>
      )}
    </div>
  );
};
