import APIHandler from '../APIHandler';
import { API, Invitation, Invite, InviteAccepted } from '../../Common/API';

export default {
  acceptInvitation: (payload, options) =>
    APIHandler.put(API.URL + InviteAccepted(payload.propertyId), payload, options),
  checkInvitation: (payload, options) =>
    APIHandler.get(
      API.URL + Invitation(payload.ut, payload.e, payload.t, payload.r, payload.p),
      payload,
      options
    )
};
