import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { HSInput, HSInputOtp, HSInputPassword } from '../../stories/Input/Input';
import { HSButton } from '../../stories/Button/Button';
import useAuth from '../../hooks/useAuth';
import './VerifyForgotPassword.scss';
import { countDownTime } from '../../utils/Common/Constant';

const VerifyForgetPassword = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const loadingState = useSelector((state: any) => state.loading);
  const selector = useSelector((state: any) => state.Auth);
  const [isOtpScreen, setIsOtpScreen] = useState(true);
  const { logOut } = useAuth();
  const [timer, setTimer] = useState(countDownTime);
  const [hasError, setHasError] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    countDown(timer);
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Please enter password'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Please make sure your password match')
        .required('Please enter confirm password')
    }),

    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Auth.verifyForgotPassword) {
        const payload: any = {
          email: state?.email,
          // password: values?.newPassword,
          newPassword: values?.newPassword,
          otp: otp
        };

        dispatch.Auth.verifyForgotPassword(payload, (resp: any) => {
          if (resp?.statusCode === 401) {
            logOut();
          } else {
            if (resp.success) {
              navigate('/');
            } else {
              setIsOtpScreen(true);
              setOtp('');
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: resp.message,
                life: 3000
              });
            }
          }
        });
      }

      resetForm();
    }
  });

  // ==========================

  const format = (time: any) => {
    let seconds: any = time % 60;
    let minutes: any = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    return minutes + ':' + seconds;
  };

  const countDown = (time: any) => {
    const _time = setInterval(() => {
      if (time > 0) time = time - 1;
      else {
        time = null;
        stop();
      }
      setTimer(time);
    }, 1000);
    const stop = () => {
      clearInterval(_time);
    };
  };

  const onClickResendCode = () => {
    const payload: any = {
      // cognitoID: selector.user.cognitoID
      email: selector?.user?.email
    };
    dispatch.Auth.forgotPassword(payload, (resp: any) => {
      if (resp?.statusCode === 401) {
        logOut();
      } else {
        if (resp.success) {
          props.toast.current.show({
            severity: 'success',
            summary: 'Success Message',
            detail: resp.message,
            life: 3000
          });
        } else {
          props.toast.current.show({
            severity: 'error',
            summary: 'Error Message',
            detail: resp.message,
            life: 3000
          });
        }
      }
    });
    // dispatch.Auth.reSendOtp(payload, (resp: any) => {
    //   if (resp?.statusCode === 401) {
    //     logOut();
    //   } else {
    //     if (resp?.success) {
    //       props.toast.current.show({
    //         severity: 'success',
    //         summary: 'Success Message',
    //         detail: resp.message,
    //         life: 3000
    //       });
    //     } else {
    //       props.toast.current.show({
    //         severity: 'error',
    //         summary: 'Error Message',
    //         detail: resp.message,
    //         life: 3000
    //       });
    //     }
    //   }
    // });
    countDown(119);
  };

  // console.log(formik, otp);

  return (
    <form>
      {isOtpScreen ? (
        <div className="verifyEmail">
          <span className="headingText">Please enter the One-Time</span>
          <span className="headingText">Password to verify your account</span>
          <p className="verifyEmailPara">A One-Time Password has been sent to your email address</p>
          <div className="Text">
            <Link to={'/'} className="linkText text-decoration-none">
              {state?.email}
            </Link>
          </div>
          <HSInputOtp
            name="otp"
            onChange={(e: any) => setOtp(e)}
            value={otp}
            isError={hasError}
            tabIndex={1}
          />
          <HSButton
            label={'Confirm OTP'}
            type="submit"
            className=""
            onClick={() => setIsOtpScreen(false)}
            tabIndex={2}
          />
          <div className="Text flex align-items-center justify-content-center verifytext">
            {timer ? (
              <span className="confirmText">Request a new verification code in </span>
            ) : (
              <span
                className="confirmText enableLink _pointer"
                onClick={() => onClickResendCode()}
                tabIndex={2}>
                Send new verification code
              </span>
            )}

            <span className=" confirmText linkText confirmCounter text-decoration-none">
              {format(timer) !== '00:00' && format(timer) !== '02:00' && format(timer)}
            </span>
          </div>
          <div></div>
        </div>
      ) : (
        <div className="ForgetBox">
          <h1>Reset Password</h1>
          <div className="">
            <HSInputPassword
              label={'New Password'}
              placeholder={'Enter your new password'}
              name="newPassword"
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              isError={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
              errorMessage={formik.errors.newPassword}
              onBlur={formik.handleBlur}
              tabIndex={4}
            />
            <br />
            <br />
            <HSInputPassword
              label={'Confirm Password'}
              placeholder={'Enter your confirm password'}
              name="confirmNewPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmNewPassword}
              isError={Boolean(
                formik.touched.confirmNewPassword && formik.errors.confirmNewPassword
              )}
              errorMessage={formik.errors.confirmNewPassword}
              onBlur={formik.handleBlur}
              tabIndex={5}
            />
            <br />
          </div>
          <HSButton
            label={!loadingState.effects.Auth.verifyForgotPassword ? 'Submit' : 'Loading...'}
            type="submit"
            className="button-forget"
            onClick={formik.handleSubmit}
          />
        </div>
      )}
    </form>
  );
};
export default VerifyForgetPassword;
