import React from 'react';
import './StatusView.scss';

const StatusView = () => {
  return (
    <>
      <div className="statusView">
        <ul className="status">
          <li>
            <span className="iconBox"></span>
            <span className="text">New Task to be review</span>
          </li>
          <li className="active">
            <span className="iconBox"></span>
            <span className="text">Under conditional approval</span>
          </li>
          <li>
            <span className="iconBox"></span>
            <span className="text">Upfront Underwriting done</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default StatusView;
