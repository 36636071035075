import React from 'react';
import { Button } from 'primereact/button';
import './button.scss';

export const HSButton = (props: any) => {
  return (
    <Button
      label={props.label}
      type={props.type}
      onClick={props?.onClick}
      className={props.className}>
      {props.children}
    </Button>
  );
};
export const HSButtonSecondary = (props: any) => {
  return (
    <Button
      className="p-button-secondary"
      label={props.label}
      type={props.type}
      onClick={props?.onClick}
    />
  );
};

export const HSButtonBlank = (props: any) => {
  return (
    <Button
      className="p-button-noBg"
      label={props.label}
      type={props.type}
      onClick={props?.onClick}
    />
  );
};
export const HSButtonBorder = (props: any) => {
  return (
    <Button
      className={`p-button-outlined ${props?.className}`}
      label={props.label}
      type={props.type}
      onClick={props?.onClick}>
      {props.children}
    </Button>
  );
};
