import Dashboard from '../../pages/Dashboard';

export const API = {
  // URL: 'https://jsonplaceholder.typicode.com',
  URL: 'http://3.7.201.6:3000/api/v1',
  URL: 'https://api.homestretchstage.in/api/v1', // New
  // URL: process.env.REACT_APP_BACK_END_BASE_URL,

  User: {
    SignUp: '/auth/register',
    loginUser: '/auth/login',
    verifyOtp: '/auth/verify-otp',
    reSendOtp: '/auth/resend-otp',
    forgotPassword: '/auth/forgot-password',
    verifyForgotPassword: '/auth/verify-forgot-password'
  },
  Property: {
    property: '/property',
    propertyDetails: '/property/page',
    myTimeline: '/timeline/my',
    createFullProperty: '/property/full'
  },
  Invite: {
    invite: '/invite',
    userRole: '/role',
    userType: '/type'
  },
  Invitation: {
    accept: '/invite'
  }
};
export const Timeline = (propertyId) => `/property/${propertyId}/timeline`;

export const Invitation = (userTypeId, email, typeId, roleId, propertyId) =>
  `/property/${propertyId}/invite/${userTypeId}/${email}/${typeId}/${roleId}`;

export const GetLoanByID = (loan_id) => `/loan/${loan_id}`;
export const InvoiceList = (practice_id) => `/api/pro/practice/${practice_id}/invoices`;
export const UpdateInvoice = (practice_id, invoice_id) =>
  `/api/pro/practice/${practice_id}/invoice/${invoice_id}/edit`;
export const SendInvoice = (practice_id, invoice_id) =>
  `/api/pro/practice/${practice_id}/invoice/${invoice_id}/send`;
export const PaidInvoice = (practice_id, invoice_id) =>
  `/api/pro/practice/${practice_id}/invoice/${invoice_id}/markaspaid`;
export const RefundInvoice = (practice_id, invoice_id) =>
  `/api/pro/practice/${practice_id}/invoice/${invoice_id}/refund`;
export const Invite = (propertyId) => `/property/${propertyId}/invite-members`;
export const InviteAccepted = (propertyId) => `/property/${propertyId}/invite-accepted`;
