import React, { useState } from 'react';
import { HSButton } from '../../stories/Button/Button';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HSInput, HSInputPassword } from '../../stories/Input/Input';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import './forgetPassword.scss';

const ForgetPassword = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingState = useSelector((state: any) => state.loading);
  const selector = useSelector((state: any) => state.Auth);
  const [checked, setChecked] = useState('');
  const { logOut } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: selector?.user.email || ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').required('Email is required')
    }),

    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Auth.forgotPassword) {
        const payload: any = {
          email: values?.email
        };
        dispatch.Auth.forgotPassword(payload, (resp: any) => {
          if (resp?.statusCode === 401) {
            logOut();
          } else {
            if (resp.success) {
              props.toast.current.show({
                severity: 'success',
                summary: 'Success Message',
                detail: resp.message,
                life: 3000
              });
              navigate('/verify-forgot-password', {
                state: {
                  email: formik.values.email
                }
              });
            } else {
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: resp.message,
                life: 3000
              });
            }
          }
        });
      }

      // resetForm();
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="ForgetBox">
        <h1>Forget Password</h1>
        <div className="">
          <HSInput
            label={'Email'}
            placeholder={'Enter your email address'}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={Boolean(formik.touched.email && formik.errors.email)}
            errorMessage={formik.errors.email}
            onBlur={formik.handleBlur}
            tabIndex={1}
          />
        </div>
        <HSButton
          label={!loadingState.effects.Auth.forgotPassword ? 'Submit' : 'Loading...'}
          type="submit"
          className="button-forget"
          tabIndex={2}
          // onClick={() => forgotPasswordHandler()}
        />
      </div>
    </form>
  );
};
export default ForgetPassword;
