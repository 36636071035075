import { AuthHandler } from '../../utils/API/API';

export const Auth = {
  state: {
    user: {}
  },
  reducers: {
    setUser(state, payload) {
      return {
        ...state,
        user: payload
      };
    },
    setUserWithVerify(state, payload) {
      return {
        ...state.user,
        ...payload
      };
    }
  },
  effects: (dispatch) => ({
    async loginUser(payload, state, callback) {
      try {
        const loginUserdata = await AuthHandler.loginUser(payload).toPromise();

        if (loginUserdata.success) {
          dispatch.Auth.setUser(loginUserdata.data);
          localStorage.setItem('bearerToken', loginUserdata.data.token);
          callback({
            success: loginUserdata.success,
            data: loginUserdata.data,
            message: loginUserdata.message,
            statusCode: loginUserdata.statusCode
          });
        } else {
          callback({
            success: loginUserdata.success,
            message: loginUserdata.message,
            statusCode: loginUserdata.statusCode
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error.message,
          statusCode: error.statusCode
        });
      }
    },

    async signUp(payload, state, callback) {
      try {
        const signUpData = await AuthHandler.signUp(payload).toPromise();

        if (signUpData.success) {
          dispatch.Auth.setUser(signUpData.data);
          callback({
            success: signUpData.success,
            data: signUpData.data,
            message: signUpData.message,
            status: signUpData.status
          });
        } else {
          callback({
            success: signUpData.success,
            message: signUpData.message,
            status: signUpData.status
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error.message,
          status: error.status
        });
      }
    },

    async verifyOtp(payload, state, callback) {
      try {
        const verifyOtpData = await AuthHandler.VerifyOtp(payload).toPromise();

        if (verifyOtpData.success) {
          dispatch.Auth.setUser(verifyOtpData.data);
          localStorage.setItem('bearerToken', verifyOtpData.data.token);
          callback({
            success: verifyOtpData.success,
            data: verifyOtpData.data,
            message: verifyOtpData.message,
            status: verifyOtpData.status
          });
        } else {
          callback({
            success: verifyOtpData.success,
            message: verifyOtpData.message,
            status: verifyOtpData.status
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error?.message,
          status: error?.status
        });
      }
    },

    async reSendOtp(payload, state, callback) {
      try {
        const reSendOtpData = await AuthHandler.reSendOtp(payload).toPromise();

        if (reSendOtpData.success) {
          callback({
            success: reSendOtpData.success,
            data: reSendOtpData.data,
            message: reSendOtpData.message,
            status: reSendOtpData.status
          });
        } else {
          callback({
            success: reSendOtpData.success,
            message: reSendOtpData.message,
            status: reSendOtpData.status
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error?.message,
          status: error?.status
        });
      }
    },

    // async checkUserExistsInSystem(payload, state, callback) {
    //   try {
    //     // const userExists = await AuthHandler.checkUserExistsInSystem(payload).toPromise();
    //     const userExists = {
    //       success: true,
    //       statusCode: 200,
    //       message: 'User Already Exists',
    //       data: {
    //         name: payload.name,
    //         phoneNumber: payload.phoneNumber,
    //         email: payload.email,
    //         userId: 101,
    //         cognitoID: 'fd54f5ds3121',
    //         isUserExists: false
    //       }
    //     };
    //     if (userExists.success) {
    //       callback({
    //         success: userExists?.data?.isUserExists,
    //         data: userExists?.data,
    //         message: userExists?.message,
    //         statusCode: userExists?.statusCode
    //       });
    //     } else {
    //       callback({
    //         success: userExists?.data?.isUserExists,
    //         message: userExists?.message,
    //         statusCode: userExists?.statusCode
    //       });
    //     }
    //   } catch (error) {
    //     console.log('error', error);
    //     callback({
    //       success: false,
    //       message: error?.message,
    //       statusCode: error?.statusCode
    //     });
    //   }
    // },

    async forgotPassword(payload, state, callback) {
      try {
        const forgotPass = await AuthHandler.forgotPassword(payload).toPromise();
        if (forgotPass.success) {
          callback({
            success: forgotPass.success,
            data: forgotPass.data,
            message: forgotPass.message,
            status: forgotPass.status
          });
        } else {
          callback({
            success: forgotPass.success,
            message: forgotPass.message,
            status: forgotPass.status
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error.message,
          status: error.status
        });
      }
    },

    async verifyForgotPassword(payload, state, callback) {
      try {
        const forgotPass = await AuthHandler.verifyForgotPassword(payload).toPromise();
        if (forgotPass.success) {
          callback({
            success: forgotPass.success,
            data: forgotPass.data,
            message: forgotPass.message,
            status: forgotPass.status
          });
        } else {
          callback({
            success: forgotPass.success,
            message: forgotPass.message,
            status: forgotPass.status
          });
        }
      } catch (error) {
        console.log('error', error);
        callback({
          success: false,
          message: error.message,
          status: error.status
        });
      }
    },

    async logOutUser(payload, state, callback) {
      try {
        // const logoutData = await AuthHandler.VerifyOtp(payload).toPromise();
        const logoutData = {
          success: true,
          statusCode: 200,
          message: 'User Logout Successfully'
        };
        console.log(logoutData, 'logoutData');
        if (logoutData.success) {
          // dispatch.Auth.setUserWithVerify(logoutData.data);
          localStorage.removeItem('bearerToken');

          callback({
            success: logoutData.success,
            data: logoutData.data,
            statusCode: logoutData.statusCode,
            message: logoutData.message
          });
        } else {
          callback({
            success: logoutData.success,
            message: logoutData.message,
            statusCode: logoutData.statusCode
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  })
};
