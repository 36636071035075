import { InviteHandler } from '../../utils/API/API';
import { Auth } from './Auth';
export const Invite = {
  state: {
    invite: [],
    userRoles: [
      { label: 'Broker', value: { name: 'Broker', roleID: 1 } },
      { label: 'Seller', value: { name: 'Seller', roleID: 2 } },
      { label: 'Buyer', value: { name: 'Buyer', roleID: 3 } },
      { label: 'Seller Agent', value: { name: 'Seller Agent', roleID: 4 } },
      { label: 'Buyer Agent', value: { name: 'Buyer Agent', roleID: 5 } },
      { label: 'Title', value: { name: 'Title', roleID: 6 } },
      { label: 'Escrow', value: { name: 'Escrow', roleID: 7 } }
    ],
    userTypes: [
      { label: 'Internal', value: { name: 'Internal', typeID: 1 } },
      { label: 'External', value: { name: 'External', typeID: 2 } },
      { label: 'Buyer', value: { name: 'Buyer', typeID: 3 } },
      { label: 'Seller', value: { name: 'Seller', typeID: 4 } }
    ]
  },
  reducers: {
    setInvite(state, payload) {
      return { ...state, invite: { ...state.invite, ...payload } };
    },
    setUserRoles(state) {
      return { ...state, userRole: { ...state.userRole } };
    },
    setUserTypes(state) {
      return { ...state, userRole: { ...state.userRole } };
    }
  },
  effects: (dispatch) => ({
    async inviteTeam(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await InviteHandler.inviteTeam(payload, options).toPromise();

        if (data.success) {
          callback({
            success: data.success,
            data: data.data,
            message: data.message,
            status: data.status
          });
        } else {
          callback({ success: data.success, message: data?.message, status: data?.status });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, status: error?.status });
      }
    },

    async userRoles(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await InviteHandler.userRole(payload, options).toPromise();
        // const data = {
        //   success: true,
        //   statusCode: 200,
        //   message: 'users role is successfully entered',
        //   data: [
        //     { name: 'Internal', typeID: 1 },
        //     { name: 'External', typeID: 2 },
        //     { name: 'Buyer', typeID: 3 },
        //     { name: 'Seller', typeID: 4 }
        //   ]
        // };
        if (data.success) {
          let roles = data.data.map((ele) => {
            return { label: ele.name, value: ele };
          });
          // const roles = [
          //   { label: 'Internal', value: { name: 'Internal', typeID: 1 } },
          //   { label: 'External', value: { name: 'External', typeID: 2 } },
          //   { label: 'Buyer', value: { name: 'Buyer', typeID: 3 } },
          //   { label: 'Seller', value: { name: 'Seller', typeID: 4 } }
          // ];

          dispatch.Invite.setUserRoles(roles);
          callback({
            success: data.success,
            data: roles,
            message: data.message,
            status: data.status
          });
        } else {
          callback({ success: data.success, message: data?.message, status: data?.status });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, status: error?.status });
      }
    },

    async userTypes(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await InviteHandler.userType(payload, options).toPromise();
        // const data = {
        //   success: true,
        //   statusCode: 200,
        //   message: 'users Type is successfully entered',
        //   data: [
        //     { name: 'Internal', typeID: 1 },
        //     { name: 'External', typeID: 1 },
        //     { name: 'Broker', typeID: 1 },
        //     { name: 'Seller', typeID: 1 }
        //   ]
        // };
        if (data.success) {
          let types = data.data.map((ele) => {
            return { label: ele.name, value: ele };
          });
          // const types = [
          //   { label: 'Broker', value: { name: 'Broker', roleID: 1 } },
          //   { label: 'Seller', value: { name: 'Seller', roleID: 2 } },
          //   { label: 'Buyer', value: { name: 'Buyer', roleID: 3 } },
          //   { label: 'Seller Agent', value: { name: 'Seller Agent', roleID: 4 } },
          //   { label: 'Buyer Agent', value: { name: 'Buyer Agent', roleID: 5 } },
          //   { label: 'Title', value: { name: 'Title', roleID: 6 } },
          //   { label: 'Escrow', value: { name: 'Escrow', roleID: 7 } }
          // ];
          dispatch.Invite.setUserTypes(types);
          callback({
            success: data.success,
            data: types,
            message: data.message,
            status: data.status
          });
        } else {
          callback({ success: data.success, message: data?.message, status: data?.status });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, status: error?.status });
      }
    }
  })
};
