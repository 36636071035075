import { Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import SignUp from './pages/Auth/SignUp';
import Dashboard from './pages/Dashboard';
import Property from './pages/Property/Property';
import VerifyOtp from './pages/Auth/VerifyOtp';
import Invite from './pages/Auth/Invite';
import AddTask from './pages/Auth/AddTask';
import PropertyInfo from './pages/Property/PropertyInfo';
import ForgotPass from './pages/Auth/ForgotPass';
import './App.css';
import VerifyForgotPass from './pages/Auth/VerifyForgotPass';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Invitation from './components/invitation/invitation';
import TimelineView from './pages/TimelineView';
import AddProperty from './pages/AddProperty/AddProperty';

function App() {
  const auth = useSelector((state: any) => state.Auth);

  useEffect(() => {
    localStorage.setItem('bearerToken', auth?.user?.token);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/verify-otp" element={<VerifyOtp />}></Route>
        <Route path="/property" element={<Property />}></Route>
        <Route path="/invite" element={<Invite />}></Route>
        {/* <Route path="/timeline" element={<Timeline />}></Route> */}
        <Route path="/propertyinfo" element={<PropertyInfo />}></Route>
        <Route path="/addtask" element={<AddTask />}></Route>
        <Route path="/forgot-password" element={<ForgotPass />}></Route>
        <Route path="/verify-forgot-password" element={<VerifyForgotPass />}></Route>
        <Route path="/invitation" element={<Invitation />}></Route>
        <Route path="/timeline-view" element={<TimelineView />}></Route>
        <Route path="/add-property" element={<AddProperty />}></Route>
      </Routes>
    </div>
  );
}

export default App;
