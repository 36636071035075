import { PropertyHandler } from '../../utils/API/API';
import { Auth } from './Auth';

export const Property = {
  state: {
    property: {},
    propertyList: [],
    myTimeline: [],
    totalProperty: 0,
    filter: {
      open: true,
      closed: false,
      search: '',
      page: 1,
      status: 'Open'
    }
  },
  reducers: {
    setProperty(state, payload) {
      return { ...state, property: { ...state.property, ...payload } };
    },
    setPropertyList(state, payload) {
      return {
        ...state,
        propertyList: [...payload.propertyList],
        totalProperty: payload.totalProperty
      };
    },
    setMyTimeline(state, payload) {
      return { ...state, myTimeline: payload };
    },
    setFilter(state, payload) {
      return { ...state, filter: { ...state.filter, ...payload } };
    },
    clearMyTimeline(state, payload) {
      return {
        ...state,
        property: {}
      };
    },
    clearProperty(state, payload) {
      return {
        ...state,
        property: {}
      };
    },
    clearPropertyList(state, payload) {
      return {
        ...state,
        property: {}
      };
    }
  },
  effects: (dispatch) => ({
    async createProperty(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await PropertyHandler.createProperty(payload, options).toPromise();
        // const data = {
        //   message: 'Property Created Successfully',
        //   success: true,
        //   statusCode: 201,
        //   data: {
        //     propertyID: 1,
        //     fullAddress: payload.fullAddress,
        //     street: payload.street,
        //     secondary: payload.secondary,
        //     city: payload.city,
        //     state: payload.state,
        //     zipcode: payload.zipcode
        //   }
        // };
        if (data.success) {
          dispatch.Property.setProperty(data.data);
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async fetchProperty(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await PropertyHandler.fetchProperty(payload, options).toPromise();
        if (data.success) {
          dispatch.Property.setPropertyList({
            propertyList: data.data,
            totalProperty: data.metaData.allCount || 0
          });
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async updatePropertyInfo(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await PropertyHandler.updatePropertyInfo(payload, options).toPromise();
        // const data = {
        //   message: 'Property Updated Successfully',
        //   success: true,
        //   statusCode: 201,
        //   data: {
        //     url: payload.url,
        //     bed: payload.bed,
        //     bath: payload.bath,
        //     sqft: payload.sqft,
        //     lotSize: payload.lotSize,
        //     price: payload.price,
        //     yearBuild: payload.yearBuild,
        //     description: payload.description
        //   }
        // };
        if (data.success) {
          dispatch.Property.setProperty(data.data);
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async clearPropertyProcess(payload, state, callback) {
      dispatch.Property.clearProperty();
    },
    async myTimeline(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await PropertyHandler.myTimeline(payload, options).toPromise();
        if (data.success) {
          dispatch.Property.setMyTimeline(data.data);
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async createFullProperty(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await PropertyHandler.createFullProperty(payload, options).toPromise();
        if (data.success) {
          dispatch.Property.setProperty(data.data);
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    updatePropertyFilter(payload) {
      dispatch.Property.setFilter(payload);
    },
    clearFilter() {
      dispatch.Property.setFilter({
        open: true,
        closed: false,
        search: '',
        page: 1,
        status: 'Open'
      });
    }
  })
};
