import { getDocId } from '../../utils/Common/Constant';

export const arrColors = [
  '#d6502b',
  '#999e0e',
  '#3e9404',
  '#046640',
  '#0aada0',
  '#034396',
  '#561c91',
  '#b009b0',
  '#db0f61',
  '#d60d14'
];
