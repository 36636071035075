import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import './Checkbox.scss';
export const HSCheckbox = (props: any) => {
  return (
    <div className="field-checkbox">
      <Checkbox inputId={props.inputId} checked={props.checked} onChange={props.onChange} />
      <label htmlFor={props.inputId}>{props.label}</label>
    </div>
  );
};
export const HSCheckbox1 = (props: any) => {
  return (
    <div className="field-checkbox">
      <Checkbox
        inputId={props.inputId}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        checked={props.checked}
      />
      <label htmlFor={props.inputId}>{props.label}</label>
    </div>
  );
};
