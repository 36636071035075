import React, { useEffect, useState } from 'react';
import { HSButton } from '../../stories/Button/Button';
import { HSInput } from '../../stories/Input/Input';
import './FilterSearch.scss';
import { Book1 } from 'iconsax-react';
import { TaskSquare } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from '../../hooks/useAuth';
const FilterSearch = (props: any) => {
  const dispatch = useDispatch();
  const property = useSelector((state: any) => state.Property);
  // console.log(property);
  const loadingState = useSelector((state: any) => state.loading);
  const { logOut } = useAuth();

  const onFilterPressed = (status: any) => {
    let payload: any = {};
    if (status === 'Open') {
      payload['open'] = !property.filter.open;
    } else if (status === 'Closed') {
      payload['closed'] = !property.filter.closed;
    }
    dispatch.Property.updatePropertyFilter(payload);
  };

  const onPropertySearch = (value: string) => {
    const payload = { search: value };
    dispatch.Property.updatePropertyFilter(payload);
  };

  useEffect(() => {
    // console.log('property filter updated');
  }, [property?.filter]);

  const onButtonClick1 = (status: any) => {
    // console.log('filter');
    // let _status = 'Open';
    // let isOpen = status === 'Open' ? !open : open;
    // let isClosed = status === 'Closed' ? !closed : closed;
    // if (status === 'Closed') {
    //   setClosed(!closed);
    // }
    // if (status === 'Open') {
    //   setOpen(!open);
    // }
    // if (isOpen && isClosed) {
    //   _status = 'Both';
    // } else if (isOpen) {
    //   _status = 'Open';
    // } else if (isClosed) {
    //   _status = 'Closed';
    // } else {
    //   _status = 'Open';
    //   setOpen(true);
    // }
    // const payload = {
    //   status: _status
    // };
    // if (!loadingState.effects.Property.fetchProperty) {
    //   dispatch.Property.fetchProperty(payload, (res: any) => {
    //     if (res?.statusCode === 401) {
    //       logOut(props.toast);
    //     } else {
    //       if (res.success) {
    //       } else {
    //         props.toast.current.show({
    //           severity: 'error',
    //           summary: 'Error Message',
    //           detail: res.message,
    //           life: 3000
    //         });
    //       }
    //     }
    //   });
    // }
  };

  return (
    <>
      <div className="filterSearch">
        <div className="btnsBox">
          <HSButton
            label={'Open'}
            onClick={() => onFilterPressed('Open')}
            className={property?.filter?.open && 'active'}>
            <Book1 color={'#1C1F25'} size={'15'} />
          </HSButton>
          <HSButton
            label={'Close'}
            onClick={() => onFilterPressed('Closed')}
            className={(property?.filter?.closed || false) && 'active'}>
            <TaskSquare color={'#1C1F25'} size={'15'} />
          </HSButton>
        </div>
        <div className="searchBx">
          <HSInput
            value={property.filter.search}
            placeholder={'Search by property address'}
            onChange={(e: any) => onPropertySearch(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default FilterSearch;
