import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import AddPropertyInfo from '../../components/AddPropertyInfo/AddPropertyInfo';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Invite1 from '../../components/Invite/index1';
import MainBanner from '../../components/MainBanner/MainBanner';
import MainHeader from '../../components/MainHeader';
import SmartyAddress from '../../components/SmartyAddress/SmartyAddress';
import { HSButton } from '../../stories/Button/Button';
import { HSDropdown } from '../../stories/SelectDropdown/SelectDropdown';
import './AddProperty.scss';

const AddProperty = (props: any) => {
  const childRef = useRef() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {} = props;
  const loadingState = useSelector((state: any) => state.loading);
  const propertyModel = useSelector((state: any) => state.Property);

  useEffect(() => {
    dispatch.Property.myTimeline({}, (res: any) => {
      console.log(res);
    });
  }, []);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.fullAddress) {
      errors.fullAddress = 'Please select address';
    }
    if (!values.fileUpload) {
      errors.fileUpload = 'Required';
    }
    if (!values.bed) {
      errors.bed = 'Required';
    }
    if (!values.bath) {
      errors.bath = 'Required';
    }
    if (!values.sqft) {
      errors.sqft = 'Required';
    }
    if (!values.lotSize) {
      errors.lotSize = 'Required';
    }
    if (!values.price) {
      errors.price = 'Required';
    }
    if (!values.year) {
      errors.year = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.fileUpload) {
      errors.fileUpload = 'Required';
    }
    if (childRef?.current?.submit()) {
    }
    if (!values.timeline.name) {
      errors.timeline = 'Required';
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullAddress: '',
      street: '',
      city: '',
      state: '',
      zipCode: '',
      bed: '',
      bath: '',
      sqft: '',
      lotSize: '',
      year: '',
      price: '',
      filePhoto: '',
      fileUpload: '',
      description: '',
      timeline: { id: '', name: '' },
      invitations: ''
    },
    validate,
    onSubmit: (values) => {
      let invitationsData = childRef?.current?.submit();
      invitationsData = invitationsData.filter(
        (item: any) =>
          item.email.trim().length > 0 &&
          Object.keys(item.type).length > 0 &&
          Object.keys(item.role).length > 0
      );
      invitationsData = invitationsData.map((item: any, idx: any) => {
        return {
          ...item,
          typeId: item?.type?.typeID,
          roleId: item?.role?.roleID
        };
      });

      const payload = {
        propertyKey: values.fullAddress,
        street: values.street,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        appartmentNumber: null,
        appartmentName: null,
        country: 'US',
        bed: values.bed,
        bath: values.bath,
        sqft: values.sqft,
        lotSize: values.lotSize,
        yearBuild: values.year,
        price: values.price,
        description: values.description,
        timelineId: values.timeline.id,
        photo: values.filePhoto,
        inviteUsers: invitationsData
      };
      dispatch.Property.createFullProperty(payload, (res: any) => {
        if (res.success) {
          navigate(-1);
        }
      });
    }
  });

  return (
    <div className="dashboardBox">
      <MainHeader />
      <MainBanner />
      <Breadcrumbs />
      <div className="sectionGrid">
        <div className="propertyBlock">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="title">1: Add your property address</div>
              <div>
                <SmartyAddress formik={formik} />
              </div>
            </div>
            <div className="divider"></div>
            <div>
              <AddPropertyInfo formik={formik} />
            </div>
            <div className="divider"></div>
            <div>
              <Invite1 formik={formik} childRef={childRef} />
            </div>
            <div className="divider"></div>
            <div>
              <div className="title">4: Select your timeline</div>
              <HSDropdown
                onChange={(e: any) => formik.setFieldValue('timeline', e.value)} //
                value={formik.values.timeline}
                // name={'timeline'}
                // optionLabel="name"
                // onBlur={(e: any) => {}}
                options={propertyModel.myTimeline}
                id={'id2'}
                optionLabel="name"
              />
              {/* {.length > 0 && <p className="errorMessage">Required *</p>} */}
              {formik.touched?.timeline && formik.errors?.timeline && (
                <small id="username2-help " className="p-error block pt-2 showError errorMessage">
                  {formik.errors?.timeline as any}
                </small>
              )}
            </div>
            <div className="wrapBtn">
              <HSButton
                label={
                  !loadingState.effects.Property.updatePropertyInfo ? 'Add Property' : 'Loading...'
                }
                type="submit"
                onClick={formik.handleSubmit}
                tabIndex={9}
              />

              <HSButton label={'Cancel'} onClick={() => navigate(-1)} tabIndex={9} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddProperty;
