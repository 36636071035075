import React, { useEffect, useState } from 'react';
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import './Pagination.scss';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { numOfPropertyInPage } from '../../utils/Common/Constant';

export const PaginatorDemo = (props: any) => {
  const dispatch = useDispatch();
  const { logOut } = useAuth();
  const [customFirst, setCustomFirst] = useState();
  const [customRows, setCustomRows] = useState(numOfPropertyInPage);
  const property = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  // const [customFirst2, setCustomFirst2] = useState(0);
  // const [customRows2, setCustomRows2] = useState(10);
  const [currentPage, setCurrentPage] = useState(property.filter.page);

  useEffect(() => {
    console.log('filter');
  }, [property.totalProperty]);

  const onCustomPageChange1 = (event: any) => {
    const previousPage = currentPage;
    const newPage = event.page + 1;
    setCustomFirst(event.first);
    setCustomRows(event.rows);
    setCurrentPage(newPage);
    dispatch.Property.updatePropertyFilter({
      page: newPage
    });
    // const payload = {
    //   limit: 15,
    //   page: newPage
    // };
    // dispatch.Property.fetchProperty(payload, (res: any) => {
    //   if (res?.statusCode === 401) {
    //     logOut(props.toast);
    //   } else {
    //     if (res.success) {
    //     } else {
    //       setCurrentPage(previousPage);
    //       props.toast.current.show({
    //         severity: 'error',
    //         summary: 'Error Message',
    //         detail: res.message,
    //         life: 3000
    //       });
    //     }
    //   }
    // });
  };

  const template = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className="p-3">Previous</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className="p-3">Next</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    }
  };
  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 120, value: 120 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Items per page:{' '}
          </span>
          {/* <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} /> */}
        </React.Fragment>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center'
          }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    }
  };

  return (
    <div className="pagination">
      <Paginator
        template={template}
        first={customFirst}
        rows={customRows}
        totalRecords={property.totalProperty}
        onPageChange={onCustomPageChange1}></Paginator>
    </div>
  );
};
