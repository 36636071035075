import React from 'react';
import MainHeader from '../../components/MainHeader';
import ListView from '../../components/TimelineView/ListView/ListView';
import ButtonsView from '../../components/TimelineView/PropertyView/ButtonsView';
import ImageView from '../../components/TimelineView/PropertyView/ImageView';
import PropertyDetailView from '../../components/TimelineView/PropertyView/PropertyDetailView';
import StatusView from '../../components/TimelineView/PropertyView/StatusView';
import './index.scss';
const TimelineView = (props: any) => {
  return (
    <>
      <MainHeader />
      <div className="sectionGrid greyBg">
        <div className="propertyView">
          <div className="propertyList">
            <ImageView />
            <PropertyDetailView />
            <StatusView />
          </div>
          <div className="btnSection">
            <ButtonsView />
          </div>
        </div>
        <div>
          <ListView />
        </div>
      </div>
    </>
  );
};

export default TimelineView;
