import React from 'react';
import { useSelector } from 'react-redux';
import CompanyLogo from '../../assets/svg/CompanyLogo';
import CustomerReview from '../CardReview/CustomerReview.';
import './SignUpSidebar.scss';
const SignUpSidebar = () => {
  const invitationModel = useSelector((state: any) => state.Invitation);
  const fullAddress = `You are invited for ${invitationModel?.invitation?.type?.name} for property - ${invitationModel.invitation?.property?.street}, ${invitationModel?.invitation?.property?.city}, ${invitationModel?.invitation.property?.state} , ${invitationModel?.invitation.property?.zipCode}  `;
  return (
    <div>
      <div className="companyInfo">
        <div className="boxWrap">
          <a href="">
            <CompanyLogo />
          </a>
          <div className="titleWrap">
            <div className="title">Start your journey with us</div>
            <div className="text">Discover world's best property task management software</div>

            {invitationModel?.invitation?.type && <div className="text">{fullAddress}</div>}
          </div>
          <CustomerReview />
        </div>
      </div>
    </div>
  );
};

export default SignUpSidebar;
