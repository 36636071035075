import React, { useState, useEffect } from 'react';
import { HSButton } from '../../stories/Button/Button';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { HSInput, HSInputPassword, HSInputMaskNumber } from '../../stories/Input/Input';
import './SignUpBox.scss';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';

const SignUpBox = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingState = useSelector((state: any) => state.loading);
  const selector = useSelector((state: any) => state.Auth);
  const invitationModel = useSelector((state: any) => state.Invitation);
  const { logOut } = useAuth();

  useEffect(() => {
    dispatch.Property.clearPropertyProcess();
  }, []);

  useEffect(() => {
    if (selector.user?.id) navigate('/dashboard');
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: invitationModel?.invitation?.e || '',
      password: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'min character should be 2')
        .max(56, 'max character should be 56')
        .required('Name is required'),

      email: Yup.string().email('Must be a valid email').required('Email is required'),

      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
      phoneNumber: Yup.string()
        .max(13, 'maximum length should be 10 digits')
        .required('Phone number is required')
    }),
    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Auth.signUp) {
        const payload: any = {
          name: values.name.trim(),
          email: values.email.toLowerCase(),
          password: values.password,
          phoneNumber: values.phoneNumber.replaceAll('-', '')
        };
        dispatch.Auth.signUp(payload, (resp: any) => {
          if (resp?.statusCode === 401) {
            logOut();
          } else {
            if (resp?.success) {
              navigate('/verify-otp');
            } else {
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: resp.message,
                life: 3000
              });
            }
          }
          // resetForm();
        });
      }
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="SignUpBox">
        <h1>Sign Up</h1>
        <div className="">
          <HSInput
            label={'Name'}
            placeholder={'Enter your name'}
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            isError={Boolean(formik.touched.name && formik.errors.name)}
            errorMessage={formik.errors.name}
            onBlur={formik.handleBlur}
            tabIndex={1}
          />
        </div>
        <div className="HSInput">
          <HSInputMaskNumber
            label={'Phone'}
            placeholder={'Enter your phone number'}
            name="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            isError={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
            errorMessage={formik.errors.phoneNumber}
            onBlur={formik.handleBlur}
            tabIndex={2}
          />
        </div>
        <div className="HSInput">
          <HSInput
            label={'Email'}
            placeholder={'Enter your email address'}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={Boolean(formik.touched.email && formik.errors.email)}
            errorMessage={formik.errors.email}
            onBlur={formik.handleBlur}
            tabIndex={3}
            disabled={invitationModel?.invitation?.e || false}
          />
        </div>

        <div className="HSInput">
          <HSInputPassword
            label={'Password'}
            name="password"
            placeholder={'Enter a strong password'}
            onChange={formik.handleChange}
            isError={Boolean(formik.touched.password && formik.errors.password)}
            errorMessage={formik.errors.password}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            tabIndex={4}
          />
        </div>
        <HSButton
          label={!loadingState.effects.Auth.signUp ? 'Sign Up' : 'Loading...'}
          type="submit"
          className="signuptextbtn "
          tabIndex={5}
        />
        <div className="mt-4 Text">
          Already a User?
          {/* <a href={'/login'} className="linkText">
            Login
          </a> */}
          <Link to={'/'} className="linkText" tabIndex={6}>
            Login
          </Link>
        </div>
      </div>
    </form>
  );
};
export default SignUpBox;
