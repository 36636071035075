import React from 'react';
import DeleteIcon from '../../../assets/svg/DeleteIcon';
import StageIcon from '../../../assets/svg/StageIcon';
import { Trash } from 'iconsax-react';
import { Edit } from 'iconsax-react';
import { TickSquare } from 'iconsax-react';
import './ListView.scss';
import { HSCheckbox, HSCheckbox1 } from '../../../stories/Checkbox/Checkbox';
import { string } from 'prop-types';

const ListView = () => {
  return (
    <div className="listBlock">
      <div className="timelineTask">
        <div className="timelineBox">
          <div className="timelineList headingText">
            <div className="timelineField">
              <div className="timelineBtn">
                {/* <div className="stageBtn" style={{ backgroundColor: ele.stageColor }}>
                      {ele.title}
                    </div> */}
                <input
                  className="stageBtn"
                  value={'ele.title'}
                  style={{ backgroundColor: '#d6502b' }}
                  //   onChange={(e) => {
                  //     let _arr = arrStages;
                  //     _arr[stageIndex]['title'] = e.target.value;
                  //     setArrStages([..._arr]);
                  //   }}
                  tabIndex={2}
                />
                <div className="wrapBtns">
                  <div className="editBtn">
                    <Edit size="14" color="#FFF" />
                  </div>
                  <div className="divider"></div>
                  <div
                    className="checkBtn"
                    // onClick={() => {
                    //   let _arr = arrStages;
                    //   if (arrStages.length > 1) {
                    //     _arr.splice(stageIndex, 1);
                    //     setArrStages([..._arr]);
                    //   } else {
                    //     if (toast) {
                    //       toast!.current!.show({
                    //         severity: 'warn',
                    //         summary: 'Warn Message',
                    //         detail: "Sorry, You can't delete section",
                    //         life: 3000
                    //       });
                    //     }
                    //   }
                    // }}
                  >
                    <TickSquare size="14" color="#FFF" />
                  </div>
                </div>
              </div>
            </div>
            <div className="timelineField">
              <div className="titleText">
                Assignee{' '}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.625 4.5H0.125L3.875 0.75L7.625 4.5Z" fill="#868C94" />
                  <path d="M0.125 9.5L7.625 9.5L3.875 13.25L0.125 9.5Z" fill="#868C94" />
                </svg>
              </div>
            </div>
            <div className="timelineField">
              <div className="titleText">
                Due Date{' '}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.625 4.5H0.125L3.875 0.75L7.625 4.5Z" fill="#868C94" />
                  <path d="M0.125 9.5L7.625 9.5L3.875 13.25L0.125 9.5Z" fill="#868C94" />
                </svg>
              </div>
            </div>
            <div className="timelineField">
              <div className="titleText">
                Complition Date{' '}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.625 4.5H0.125L3.875 0.75L7.625 4.5Z" fill="#868C94" />
                  <path d="M0.125 9.5L7.625 9.5L3.875 13.25L0.125 9.5Z" fill="#868C94" />
                </svg>
              </div>
            </div>
            <div className="timelineField"></div>
          </div>
          {/* {ele?.tasks.map((task: any, taskIndex: number) => ( */}
          {[1, 2, 3, 4, 5].map((i) => {
            return (
              <div className="timelineList" key={`${i}`}>
                <div className="timelineField inputWrap">
                  {/* <div className="taskTitle">{task.name}</div> */}
                  <HSCheckbox inputId={'checkbox1'} checked={false} />
                  <input
                    className="taskTitle"
                    value={'task.title'}
                    //   onChange={(e) => {
                    //     let _arr = arrStages;
                    //     _arr[stageIndex]['tasks'][taskIndex]['title'] = e.target.value;
                    //     setArrStages([..._arr]);
                    //   }}
                    tabIndex={3}
                  />
                </div>
                <div className="timelineField">
                  <div className="assignee">
                    <span className="assigneeImg">
                      <img src="images/ionibowcher.png" alt="" />
                    </span>
                    <span className="assigneeName">Dusty I. Tillett</span>
                  </div>
                </div>
                <div className="timelineField">
                  <div className="dueDate">11/01/22</div>
                </div>
                <div className="timelineField">
                  <div className="complitionDate">16/02/22</div>
                </div>
                <div className="timelineField">
                  <div className="wrapBtns">
                    <div className="editBtn">
                      <Edit size="22" color="#868C94" />
                    </div>
                    <div
                      className="deleteBtn"
                      // onClick={() => {
                      //   let _arr = arrStages;
                      //   _arr[stageIndex]['tasks'].splice(taskIndex, 1);
                      //   setArrStages([..._arr]);
                      // }}
                    >
                      <Trash size="22" color="#868C94" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* ))} */}
        </div>
        <div className="iconSet">
          {/* <div
                className="addNew"
                // onClick={() => {
                //   let _arr: any = arrStages;
                //   _arr.splice(stageIndex + 1, 0, {
                //     stageUUID: getDocId(),
                //     title: '#Stage',
                //     stageColor: arrColors[Math.floor(Math.random() * 10)],
                //     tasks: [
                //       {
                //         taskUUID: getDocId(),
                //         title: '#Task',
                //         internalPermission: '',
                //         externalPermission: ''
                //       }
                //     ],
                //     setUpCompleted: false
                //   });
                //   setArrStages([..._arr]);
                // }}
                >
                <span className="iconBox">
                  <StageIcon />
                </span>
                ADD NEW STAGE{' '}
              </div> */}
          <div
            className="addUser"
            // onClick={() => {
            //   let _arr = arrStages;
            //   _arr[stageIndex]['tasks'].push({
            //     taskUUID: getDocId(),
            //     title: '#task',
            //     internalPermission: '',
            //     externalPermission: ''
            //   });
            //   setArrStages([..._arr]);
            // }}
          >
            + New task{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListView;
