import React, { useRef } from 'react';
import SignUpProgressSidebar from '../../components/SignUpProgressSidebar/SignUpProgressSidebar';
import PropertyBox from '../../components/PropertyBox/PropertyBox';
import { Toast } from 'primereact/toast';

const Property = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <div className="grid align-items-center">
          <div className="col-12 md:col-6 xl:col-4">
            <SignUpProgressSidebar />
          </div>
          <div className="col-12 md:col-6 xl:col-8">
            <PropertyBox toast={toast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Property;
