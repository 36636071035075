import React, { useRef } from 'react';
import ForgetPassword from '../../components/ForgetPassword/ForgetPassword';
import SignUpSidebar from '../../components/SignUpSidebar/SignUpSidebar';
import { Toast } from 'primereact/toast';

const ForgotPass = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <div className="grid align-items-center">
          <div className="col-12 md:col-6 xl:col-4">
            <SignUpSidebar />
          </div>
          <div className="col-12 md:col-6 xl:col-8">
            <ForgetPassword toast={toast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
