import React, { useEffect } from 'react';
import { useState } from 'react';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import { Trash } from 'iconsax-react';
import { HSButton, HSButtonBlank } from '../../stories/Button/Button';
import { HSInput } from '../../stories/Input/Input';
import { HSDropdown } from '../../stories/SelectDropdown/SelectDropdown';
import { Link, useNavigate } from 'react-router-dom';
import './Invite.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Secondary } from '../../stories/Button/Button.stories';
import useAuth from '../../hooks/useAuth';
import * as Constant from '../../utils/Common/Constant';
import { ArrowLeft } from 'iconsax-react';

const Invite = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const auth = useSelector((state: any) => state.Auth);
  const InviteModel = useSelector((state: any) => state.Invite);
  const propertyModel = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  const [selectUserRoles, setSelectUserRoles] = useState(InviteModel.userRoles || []);
  const [selectUserTypes, setSelectUserTypes] = useState(InviteModel.userTypes || []);

  useEffect(() => {
    // Get UserRolesonChangeEmailValidate
    // Get UserTypes
    // dispatch.Invite.userTypes((res: any) => {
    //   if (res.success) {
    //     let types = res.data.map((ele: any) => {
    //       return { label: ele.name, value: ele };
    //     });
    //     setSelectUserTypes(types);
    //   } else {
    //     props.toast.current.show({
    //       severity: 'error',
    //       summary: 'Error Message',
    //       detail: res.message,
    //       life: 3000
    //     });
    //   }
    // });
  });

  const [teamData, setTeamData] = useState([
    {
      email: '',
      type: {}, // Object.getAllKeys(item.type).lenght> 0
      role: {},
      emailError: '',
      typesError: '',
      rolesError: ''
    }
  ]);
  const handleOnchangeEvent = (index: number, e: any, key: string) => {
    let data: any = [...teamData];
    data[index][key] = e.target.value;
    setTeamData(data);
  };
  const handleOnBlurEmail = (index: number, e: any, key: string) => {
    // console.log('~~~~~~~~~~~~~~~~~~~~~~>', e.target.value);
    let isValidEmail = Constant.isValidEmail(e.target.value);
    // console.log('isValidEmail', isValidEmail);
    if (isValidEmail) {
      let data: any = [...teamData];
      data[index]['emailError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['emailError'] = 'Please enter correct email';
      setTeamData(data);
    }
  };

  const handleOnBlurDropdown = (index: number, e: any, key: string) => {
    let dataValue = e.value;
    let data: any = [...teamData];
    // console.log(e, data);
    if (dataValue) {
      data[index]['typesError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['typesError'] = 'Required';
      setTeamData(data);
    }
  };

  const handleOnBlurDropdownRole = (index: number, e: any, key: string) => {
    let dataValue = e.value;
    let data: any = [...teamData];
    console.log(e, data);
    if (dataValue) {
      data[index]['rolesError'] = '';
      setTeamData(data);
    } else {
      let data: any = [...teamData];
      data[index]['rolesError'] = 'Required';
      setTeamData(data);
    }
  };
  const handleTeamAdd = () => {
    setTeamData([
      ...teamData,
      {
        email: '',
        type: {},
        role: {},
        emailError: '',
        typesError: '',
        rolesError: ''
      }
    ]);
  };
  const handleTeamRemove = (index: any) => {
    const data = teamData.filter((i, inx) => inx !== index);
    setTeamData(data);
  };

  const handleSubmitNext = () => {
    let hasError = false;
    let _teamData: any = teamData;
    _teamData = _teamData.map((item: any, idx: any) => {
      if (item.email === '') {
        item.emailError = 'Enter valid email *';
        hasError = true;
      }
      if (Object.keys(item.role).length === 0) {
        item.rolesError = 'Required';
        hasError = true;
      }
      if (Object.keys(item.type).length === 0) {
        item.typesError = 'Required';
        hasError = true;
      }
      console.log(_teamData);

      return {
        ...item,
        typeId: item?.type?.typeID,
        roleId: item?.role?.roleID
      };
    });
    setTeamData([..._teamData]);

    if (!loadingState.effects.Invite.inviteTeam && !hasError) {
      const payload = {
        propertyId: propertyModel?.property?.id,
        userId: auth?.user?.id,
        inviteUsers: _teamData
      };
      dispatch.Invite.inviteTeam(payload, (res: any) => {
        if (res?.statusCode === 401) {
          logOut();
        } else {
          if (res.success) {
            navigate('/addtask');
          } else {
            props.toast.current.show({
              severity: 'error',
              summary: 'Error Message',
              detail: res.message,
              life: 3000
            });
          }
        }
      });
    }
  };
  return (
    <>
      <div className="inviteBox">
        <div className="memberBox">
          <h1>Invite Team Members</h1>
          <div className="labelWrap">
            <label>Email Address</label>
            <label>User Type</label>
            <label>User Role</label>
            <label></label>
          </div>
          <div className="userWrap">
            {teamData?.map((item, index) => {
              return (
                <div className="inviteUser" key={index}>
                  <div className="inputWrap" key="index">
                    <div className="inputBx">
                      <HSInput
                        type="email"
                        placeholder={'john@email.com'}
                        name={'email'}
                        onChange={(e: any) => handleOnchangeEvent(index, e, 'email')}
                        onBlur={(e: any) => handleOnBlurEmail(index, e, 'error')}
                        value={item.email}
                        id="id1"
                      />
                      {item.emailError.length > 0 && (
                        <p className="errorMessage">Enter valid email</p>
                      )}
                    </div>
                    <div className="dropDownWrap">
                      <HSDropdown
                        onChange={(e: any) => handleOnchangeEvent(index, e, 'type')}
                        value={item.type}
                        name={'type'}
                        onBlur={(e: any) => handleOnBlurDropdown(index, e, 'error')}
                        options={selectUserTypes}
                        id={'id3'}
                      />
                      {item.typesError.length > 0 && <p className="errorMessage">Required *</p>}
                    </div>

                    <div className="dropDownWrap">
                      <HSDropdown
                        onChange={(e: any) => handleOnchangeEvent(index, e, 'role')}
                        value={item.role}
                        name={'role'}
                        onBlur={(e: any) => handleOnBlurDropdownRole(index, e, 'error')}
                        options={selectUserRoles}
                        id={'id2'}
                      />
                      {item.rolesError.length > 0 && <p className="errorMessage">Required *</p>}
                    </div>
                    <div className="wrapBtns">
                      <div className="deleteBtn">
                        {(index < teamData.length - 1 ||
                          (index === teamData.length - 1 && teamData.length > 1)) && (
                          <div className="delUser" onClick={() => handleTeamRemove(index)}>
                            <Trash size="22" color="#FF4848" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {(index < teamData.length - 1 ||
                    (index === teamData.length - 1 && teamData.length > 1)) && (
                    <div className="delUser" onClick={() => handleTeamRemove(index)}>
                      <DeleteIcon />
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
          <div className="addUser" onClick={handleTeamAdd} tabIndex={4}>
            + Add New User
          </div>
        </div>

        <div className="btnsWrap">
          <div className="backBtn " onClick={() => navigate(-1)} tabIndex={8}>
            <ArrowLeft size={'20'} color={'#868C94'} />
            Back
          </div>
          <div>
            <HSButtonBlank
              label={'Skip'}
              tabIndex={7}
              onClick={() => navigate('/addtask')}
              className="p-button-noBg"
            />
            <HSButton
              label={!loadingState.effects.Invite.inviteTeam ? 'Next' : 'Loading...'}
              type="submit"
              onClick={handleSubmitNext}
              tabIndex={6}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Invite;
