import APIHandler from '../APIHandler';
import { API, InvoiceList, SendInvoice, RefundInvoice } from '../../Common/API';

export default {
  invoiceList: (data) => APIHandler.get(API.URL + InvoiceList(data.practice_id)),
  sendInvoice: (data) =>
    APIHandler.post(API.URL + SendInvoice(data.practice_id, data.invoice_id), data),
  refundInvoice: (data) =>
    APIHandler.put(API.URL + RefundInvoice(data.practice_id, data.invoice_id), data)
};
