import { InvoiceHandler } from '../../utils/API/API';
import { API, UpdateInvoice, PaidInvoice } from '../../utils/Common/API';

export const Invoice = {
  state: {
    invoices: [],
    extraVariable: 'notSaved'
  },
  reducers: {
    setInvoices(state, payload) {
      return {
        ...state,
        invoices: payload
      };
    },
    setUpdatedInvoice(state, payload) {
      let updatedObject = payload;
      let array = state.invoices.map((item) => {
        return item._id === updatedObject._id ? updatedObject : item;
      });
      return {
        ...state,
        invoices: array
      };
    },
    setSentInvoice(state, payload) {
      let sendInvoiceObject = payload;
      let array = state.invoices.map((item) => {
        return item._id === sendInvoiceObject.id
          ? {
              ...item,
              published: true,
              revision: 1,
              state: 'Due',
              isDraft: false,
              isDue: true
            }
          : item;
      });
      return {
        ...state,
        invoices: array
      };
    },
    setPaidInvoice(state, payload) {
      let updatedObject = payload;
      let array = state.invoices.map((item) => {
        return item._id === updatedObject._id
          ? {
              ...updatedObject,
              state: 'Paid',
              isDue: false,
              payment: {
                objservation: {
                  ...updatedObject.payment.objservation,
                  receipt: null
                }
              }
            }
          : item;
      });
      return {
        ...state,
        invoices: array
      };
    },
    setRefundInvoice(state, payload) {
      let updatedObject = payload;
      let array = state.invoices.map((item) => {
        return item._id === updatedObject._id ? updatedObject : item;
      });
      return {
        ...state,
        invoices: array
      };
    },
    setVoidInvoice(state, payload) {
      let updatedObject = payload;
      let array = state.invoices.map((item) => {
        return item._id === updatedObject._id
          ? {
              ...updatedObject,
              isDue: false,
              isVoid: true,
              payment: {
                objservation: {
                  ...updatedObject.payment.objservation,
                  receipt: null
                }
              },
              state: 'void'
            }
          : item;
      });
      return {
        ...state,
        invoices: array
      };
    }
  },
  effects: (dispatch) => ({
    async getInvoices(payload) {
      try {
        const data = await InvoiceHandler.invoiceList(payload).toPromise();
        if (!data.error.length) dispatch.Invoice.setInvoices(data.invoices);
      } catch (error) {
        console.log('error', error);
      }
    },
    async updateInvoice(payload, arg1, callback) {
      try {
        const response = await fetch(
          API.URL + UpdateInvoice(payload.get('practice_id'), payload.get('invoice_id')),
          {
            method: 'PUT',
            body: payload
          }
        );
        if (!response.ok) {
        }
        const updatedInvoice = await response.json();
        callback(updatedInvoice);
        dispatch.Invoice.setUpdatedInvoice(updatedInvoice);
      } catch (error) {
        console.log('error', error);
      }
    },
    async sendInvoice(payload, arg1, callback) {
      try {
        const data = await InvoiceHandler.sendInvoice(payload).toPromise();
        callback(data);
        dispatch.Invoice.setSentInvoice(data);
      } catch (error) {
        console.log('error', error);
      }
    },
    async paidInvoice(payload, arg1, callback) {
      try {
        const response = await fetch(
          API.URL + PaidInvoice(payload.get('practice_id'), payload.get('invoice_id')),
          {
            method: 'PUT',
            body: payload
          }
        );
        if (!response.ok) {
          console.log('error in Invoice/paidInvoice');
        }
        const updatedInvoice = await response.json();
        callback(updatedInvoice);
        dispatch.Invoice.setPaidInvoice(updatedInvoice);
      } catch (error) {
        console.log('error', error);
      }
    },
    async refundInvoice(payload, arg1, callback) {
      try {
        const data = await InvoiceHandler.refundInvoice(payload).toPromise();
        callback(data.updatedInvoice);
        dispatch.Invoice.setRefundInvoice(data.updatedInvoice);
      } catch (error) {
        console.log('error', error);
      }
    },
    async voidInvoice(payload, arg1, callback) {
      try {
        const response = await fetch(
          API.URL + UpdateInvoice(payload.get('practice_id'), payload.get('invoice_id')),
          {
            method: 'PUT',
            body: payload
          }
        );
        if (!response.ok) {
        }
        const updatedInvoice = await response.json();
        callback(updatedInvoice);
        dispatch.Invoice.setVoidInvoice(updatedInvoice);
      } catch (error) {
        console.log('error', error);
      }
    }
  })
};
