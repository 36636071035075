import React from 'react';
import SignUpProgressSidebar from '../../components/SignUpProgressSidebar/SignUpProgressSidebar';
import InviteComponent from '../../components/Invite';

const Invite = () => {
  return (
    <div>
      <div className="wrap py-3">
        <div className="grid align-items-center">
          <div className="col-12 md:col-5 xl:col-4">
            <SignUpProgressSidebar />
          </div>
          <div className="col-12 md:col-7 xl:col-8">
            <InviteComponent />
            {/* <VerifyEmail /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
