import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import AddTaskComponent from '../../components/AddTask';

const AddTask = () => {
  const toast = useRef(null);

  return (
    <>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <AddTaskComponent toast={toast} />
      </div>
    </>
  );
};

export default AddTask;
