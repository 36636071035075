import { useState } from 'react';
import AWS from 'aws-sdk';
import { FileUpload } from 'primereact/fileupload';
import moment from 'moment';
import './AddPropertyInfo.scss';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Trash } from 'iconsax-react';

const AddPropertyInfo = (props: any) => {
  const [progress, setProgress] = useState(0);
  const [hasPropertyImageSelected, setPropertyImageSelected] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const { formik } = props;
  const S3_BUCKET = 'homestretchdev-files';
  const REGION = 'us-east-1';

  AWS.config.update({
    accessKeyId: 'AKIAZWCCEDRNCIPX5INN',
    secretAccessKey: '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'
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION
  });

  const invoiceUploadHandler = ({ files }: any) => {
    if (files.length > 0) {
      setFileURL(files[0].objectURL);
      formik.setFieldValue('fileUpload', files);
      uploadFileToS3(files[0]);
    }
  };

  const uploadFileToS3 = (file: any) => {
    const _key = `properties/temp/${moment().unix()}-${file.name}`;
    const params: any = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: _key
    };
    myBucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on('complete', (evt) => {
        console.log('~~~~ File Uploaded ~~~~', evt);
        formik.setFieldValue('filePhoto', _key);
      })
      .on('error', (evt) => {
        console.error('file upload error');
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const alertClick = () => {
    setPropertyImageSelected(false);
    setFileURL(null);
  };
  return (
    <div className="addPropertyInfo">
      <div className="propertyInfoBox">
        <h1>2: Add Additional Detail</h1>
        <form>
          <div className="propertyInfoMain grid ">
            <div className="propertyUpload col-12 md:col-3">
              <div>
                <label>Add Image</label>
                {fileURL ? (
                  <div className="imgWrap">
                    <img src={fileURL} className="uploadImg" />
                    <div onClick={() => alertClick()} className="delUser imgDel">
                      <Trash size="22" color="#FFF" />
                    </div>
                  </div>
                ) : (
                  <FileUpload
                    name="invoice"
                    // onUpload={onBasicUpload}
                    accept=".jpg,.jpeg,.png"
                    customUpload={true}
                    uploadHandler={invoiceUploadHandler}
                    mode="basic"
                    auto={true}
                    chooseLabel="Upload Image"
                  />
                )}
              </div>
              {formik.touched.fileUpload && formik.errors.fileUpload && (
                <small id="username2-help " className="p-error block pt-2 showError errorMessage">
                  {formik.errors['bed'] as any}
                </small>
              )}
            </div>

            <div className="Propertyform  col-12 md:col-9">
              <div className="grid">
                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>Numbers of Bedrooms</label>
                    <InputNumber
                      name="Bedrooms"
                      placeholder={'Bedrooms'}
                      value={formik.values.bed}
                      tabIndex={1}
                      onBlur={formik.handleBlur}
                      onValueChange={(e: any) => {
                        formik.setFieldValue('bed', e.target.value);
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('bed', e.value);
                      }}
                      minFractionDigits={0}
                      maxFractionDigits={1}
                      mode="decimal"
                      min={0}
                      max={99}
                    />
                    {formik.touched['bed'] && formik.errors['bed'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['bed'] as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>Numbers of Bathrooms</label>
                    <InputNumber
                      name="Bathrooms"
                      placeholder={'Bathrooms'}
                      value={formik.values.bath}
                      tabIndex={2}
                      onBlur={formik.handleBlur}
                      onValueChange={(e: any) => {
                        formik.setFieldValue('bath', e.target.value);
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('bath', e.value);
                      }}
                      minFractionDigits={0}
                      maxFractionDigits={1}
                      mode="decimal"
                      min={0}
                      max={99}
                    />
                    {formik.touched['bath'] && formik.errors['bath'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['bath'] as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>SqFt</label>
                    <InputNumber
                      name="SqFt"
                      placeholder={'SqFt'}
                      value={formik.values.sqft}
                      tabIndex={3}
                      onBlur={formik.handleBlur}
                      onValueChange={(e: any) => {
                        formik.setFieldValue('sqft', e.target.value);
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('sqft', e.value);
                      }}
                      minFractionDigits={0}
                      maxFractionDigits={2}
                      mode="decimal"
                      min={0}
                      max={99999}
                    />
                    {formik.touched['sqft'] && formik.errors['sqft'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['sqft'] as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>Lot Size</label>
                    <InputNumber
                      name="LotSize"
                      placeholder={'LotSize'}
                      value={formik.values.lotSize}
                      tabIndex={4}
                      onBlur={formik.handleBlur}
                      onValueChange={(e: any) => {
                        formik.setFieldValue('lotSize', e.target.value);
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('lotSize', e.value);
                      }}
                      minFractionDigits={0}
                      maxFractionDigits={2}
                      mode="decimal"
                      min={0}
                      max={99999}
                    />
                    {formik.touched['lotSize'] && formik.errors['lotSize'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['lotSize'] as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>Year Build</label>
                    <Calendar
                      id="YearBuilt"
                      minDate={new Date('1900')}
                      maxDate={new Date()}
                      tabIndex={5}
                      value={formik.values.year}
                      onChange={(e) => formik.setFieldValue('year', e.value)}
                      view="year"
                      dateFormat="yy"
                    />
                    {formik.touched['year'] && formik.errors['year'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['year'] as any}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-12 md:col-6">
                  <div className="inputBox">
                    <label>Property Price</label>
                    <InputNumber
                      name="PropertyPrice"
                      inputId="currency-us"
                      placeholder={'Property Price'}
                      value={formik.values.price}
                      tabIndex={6}
                      onBlur={formik.handleBlur}
                      onValueChange={(e: any) => {
                        formik.setFieldValue('price', e.target.value);
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('price', e.value);
                      }}
                      max={99999999}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                    {formik.touched['price'] && formik.errors['price'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['price'] as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <div className="textareaBox">
                    <label>Description</label>
                    <InputTextarea
                      name="Description"
                      placeholder={'Description'}
                      value={formik.values.description}
                      tabIndex={7}
                      onBlur={(e: any) => {
                        formik.handleBlur('description');
                        formik.setFieldValue('description', e.target.value);
                      }}
                      // rows={5}
                      // cols={30}
                      onChange={(e: any) => {
                        formik.setFieldValue('description', e.target.value);
                      }}
                    />
                    {formik.touched['description'] && formik.errors['description'] && (
                      <small
                        id="username2-help "
                        className="p-error block pt-2 showError errorMessage">
                        {formik.errors['description'] as any}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* <HSButton label={'Confirm'} type="submit" /> */}
      </div>
    </div>
  );
};

export default AddPropertyInfo;
