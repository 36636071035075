import React from 'react';
import './Breadcrumbs.scss';
const Breadcrumbs = () => {
  return (
    <>
      <ul className="breadcrumbs">
        <li>
          <a href="#">Dashboard</a>
        </li>
        <i className="pi pi-chevron-right"></i>
        <li>My Properties</li>
      </ul>
    </>
  );
};

export default Breadcrumbs;
