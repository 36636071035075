import React from 'react';
import './PropertyDetailView.scss';

const PropertyDetailView = () => {
  return (
    <>
      <div className="propertyDetailView">
        <div className="title">39 Golden Eagle</div>
        <div className="location">Irvine, CA 92603</div>
        <ul className="list">
          <li>
            3<span>Bed</span>
          </li>
          <li>
            2<span>Bath</span>
          </li>
          <li>
            2700<span>Sqft</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PropertyDetailView;
