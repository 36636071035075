import { InvitationHandler } from '../../utils/API/API';
import { Auth } from './Auth';

export const Invitation = {
  state: {
    invitation: {}
  },
  reducers: {
    setInvitation(state, payload) {
      return { ...state, invitation: payload };
    },
    clearProperty(state, payload) {
      return {
        ...state,
        invitation: {}
      };
    }
  },
  effects: (dispatch) => ({
    async checkInvitation(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await InvitationHandler.checkInvitation(payload, options).toPromise();
        // const data = {
        //   success: true,
        //   message: '',
        //   data: {
        //     userInfo: {
        //       id: 1,
        //       name: 'HSDev',
        //       userTypeID: payload.ut,
        //       email: payload.email,
        //       typeId: payload.t,
        //       roleId: payload.r,
        //       propertyId: payload.p
        //     }
        //   }
        // };

        if (data.success) {
          dispatch.Invitation.setInvitation({ ...payload, ...data?.data });
          callback({
            success: true,
            data: { ...payload, ...data?.data },
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async acceptInvitation(payload, state, callback) {
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await InvitationHandler.acceptInvitation(payload, options).toPromise();

        if (data.success) {
          dispatch.Invitation.setInvitation(data.data);
          callback({
            success: true,
            data: data.data,
            message: data.message,
            statusCode: data.statusCode
          });
        } else {
          callback({ success: false, message: data?.message, statusCode: data?.statusCode });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, statusCode: error?.statusCode });
      }
    },
    async clearInvitationProcess(payload, state, callback) {
      dispatch.Property.clearProperty();
    }
  })
};
