import createLoadingPlugin from '@rematch/loading';

export const loadingPlugin = createLoadingPlugin({
  whitelist: [
    'Auth/loginUser',
    'Auth/signUp',
    'Auth/verifyOtp',
    'Auth/reSendOtp',
    'Auth/forgotPassword',
    'Auth/verifyForgotPassword',
    'Auth/logOutUser',
    'Property/createProperty',
    'Property/updatePropertyInfo',
    'Invite/inviteTeam',
    'Timeline/updateTimeline',
    'Property/fetchProperty',
    'Property/filterProperty',
    'Invitation/acceptInvitation'
  ]
});
