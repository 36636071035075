import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HSButton, HSButtonBorder } from '../../stories/Button/Button';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import FilterSearch from '../FilterSearch/FilterSearch';
import MainBanner from '../MainBanner/MainBanner';
import MainCard from '../MainCard/MainCard';
import MainHeader from '../MainHeader';
import { PaginatorDemo } from '../Pagination/Pagination';
import './DashboradBox.scss';
import { Home } from 'iconsax-react';

import { useNavigate } from 'react-router-dom';

const DashboardBox = () => {
  const property = useSelector((state: any) => state.Property);

  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="addProperty">
      <MainHeader />
      {true ? (
        <>
          <MainBanner />
          <Breadcrumbs />
          <div>
            <div className="filterSection">
              <FilterSearch />
            </div>
            <div className="paginationSection">
              <div className="totalResult">
                {`Total Results:  ${property.filter.page * 10 - 10} to ${
                  property.filter.page * 10
                } of ${property.totalProperty} entries`}
              </div>
              <PaginatorDemo />
              <div className="btnSet">
                <HSButtonBorder
                  label={'Clear Filters'}
                  className="mr-4"
                  onClick={() => dispatch.Property.clearFilter()}
                />

                <div className="dialog-demo">
                  <div className="card">
                    <HSButton label={'Add Property'} onClick={() => navigate('/add-property')}>
                      <Home color={'#fff'} size={'20'} />
                    </HSButton>
                  </div>
                </div>
              </div>
            </div>
            <MainCard toast={toast} />
          </div>
        </>
      ) : (
        <div>No property found</div>
      )}
    </div>
  );
};
export default DashboardBox;
