export const LocationIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2180_32297)">
        <path
          d="M21 10.7976C21 17.7405 12 23.6915 12 23.6915C12 23.6915 3 17.7405 3 10.7976C3 8.43016 3.94821 6.15966 5.63604 4.48561C7.32387 2.81156 9.61305 1.87109 12 1.87109C14.3869 1.87109 16.6761 2.81156 18.364 4.48561C20.0518 6.15966 21 8.43016 21 10.7976Z"
          stroke="#868C94"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 13.7731C13.6569 13.7731 15 12.441 15 10.7976C15 9.1543 13.6569 7.82211 12 7.82211C10.3431 7.82211 9 9.1543 9 10.7976C9 12.441 10.3431 13.7731 12 13.7731Z"
          stroke="#868C94"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2180_32297">
          <rect width="24" height="23.8041" fill="white" transform="translate(0 0.878906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LocationIconSm = () => {
  return (
    <svg width="20" height="20" x="0" y="0" viewBox="0 0 682 682.66669">
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m216.210938 0c-122.664063 0-222.460938 99.796875-222.460938 222.460938 0 154.175781 222.679688 417.539062 222.679688 417.539062s222.242187-270.945312 222.242187-417.539062c0-122.664063-99.792969-222.460938-222.460937-222.460938zm67.121093 287.597656c-18.507812 18.503906-42.8125 27.757813-67.121093 27.757813-24.304688 0-48.617188-9.253907-67.117188-27.757813-37.011719-37.007812-37.011719-97.226562 0-134.238281 17.921875-17.929687 41.761719-27.804687 67.117188-27.804687 25.355468 0 49.191406 9.878906 67.121093 27.804687 37.011719 37.011719 37.011719 97.230469 0 134.238281zm0 0"
          fill="#b3b3b3"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};
