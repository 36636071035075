import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import './SelectDropdown.scss';

export const HSDropdown = (props: any) => {
  return (
    <div className="dropdownBox">
      <label>{props.label}</label>
      <Dropdown
        value={props.value}
        placeholder={props.placeholder}
        options={props.options}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={props.className}
        optionLabel={props?.optionLabel}
      />
    </div>
  );
};

export const HSMultiSelect = (props: any) => {
  return (
    <div className="multiselectBox">
      <label>{props.label}</label>
      <MultiSelect
        value={props.value}
        placeholder={props.placeholder}
        options={props.options}
        onChange={(e) => props.setValue(e.value)}
        maxSelectedLabels={3}
        display="chip"
      />
    </div>
  );
};
