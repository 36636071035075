import { TimelineHandler } from '../../utils/API/API';
import { getDocId } from '../../utils/Common/Constant';
import { Auth } from './Auth';
import { Property } from './Property';

export const Timeline = {
  state: {
    timeline: {
      id: 1,
      template: {
        title: 'Hello Timeline',
        templateUUID: getDocId(),
        stages: [
          {
            stageUUID: getDocId(),
            title: 'Requirement Gethering',
            stageColor: '#d6502b',
            tasks: [
              {
                taskUUID: getDocId(),
                title: 'Collect document from client',
                internalPermission: 'Can Edit',
                externalPermission: 'Can Edit'
              },
              {
                taskUUID: getDocId(),
                title: 'Document from client',
                internalPermission: 'Can Edit',
                externalPermission: 'Can Edit'
              }
            ]
          },
          {
            stageUUID: getDocId(),
            title: 'Design',
            stageColor: '#0aada0',
            tasks: [
              {
                taskUUID: getDocId(),
                title: 'SetUp Figma',
                internalPermission: 'Can Edit',
                externalPermission: 'Can Edit'
              }
            ]
          }
        ]
      }
    }
  },
  reducers: {
    setStageList(state, payload) {
      return { ...state, addStage: { ...state.addStage, ...payload } };
    }
  },
  effects: (dispatch) => ({
    async updateTimeline(payload, state, callback) {
      // const propertyId = Property.state.property.id;
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await TimelineHandler.updateTimeline(
          payload.propertyId,
          payload,
          options
        ).toPromise();

        if (data.success) {
          dispatch.Timeline.setStageList(data.data);
          callback({
            success: data.success,
            data: data.data,
            message: data.message,
            status: data.status
          });
        } else {
          callback({ success: data.success, message: data?.message, status: data?.status });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, status: error?.status });
      }
    },
    async defaultTimeline(payload, state, callback) {
      const propertyId = Property.state.property.id;
      let options = {
        cognitoID: Auth.state.user.cognitoID,
        Authorization: `Bearer ${Auth.state.user.token}`
      };
      try {
        const data = await TimelineHandler.defaultTimeline(
          payload.id,
          payload,
          options
        ).toPromise();

        if (data.success) {
          dispatch.Timeline.setStageList(data.data);
          callback({
            success: data.success,
            data: data.data,
            message: data.message,
            status: data.status
          });
        } else {
          callback({ success: data.success, message: data?.message, status: data?.status });
        }
      } catch (error) {
        console.log(error);
        callback({ success: false, message: error?.message, status: error?.status });
      }
    }
  })
};
