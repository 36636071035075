import React, { useRef } from 'react';
import SignUpSidebar from '../../components/SignUpSidebar/SignUpSidebar';
import VerifyEmail from '../../components/VerifyEmail/VerifyEmail';
import { Toast } from 'primereact/toast';
import './Login.scss';
const VerifyOtp = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <div className="grid align-items-center">
          <div className="col-12 md:col-6 xl:col-4">
            <SignUpSidebar />
          </div>
          <div className="col-12 md:col-6 xl:col-8">
            <VerifyEmail toast={toast} />
            {/* <VerifyEmail /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifyOtp;
