import { useState } from 'react';
import { useFormik } from 'formik';
import './PropertyInfoBox.scss';
import { FileUpload } from 'primereact/fileupload';
import { HSButton, HSButtonBlank } from '../../stories/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { ArrowLeft } from 'iconsax-react';
import AWS from 'aws-sdk';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Trash } from 'iconsax-react';

const S3_BUCKET = 'homestretchdev-files';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIAZWCCEDRNCIPX5INN',
  secretAccessKey: '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'
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

const PropertyInfoBox = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => state.Property);
  const auth = useSelector((state: any) => state.Auth);
  const propertyModel = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  const [fileURL, setFileURL] = useState(selector?.property?.photo || null);
  const [progress, setProgress] = useState(0);
  const [hasPropertyImageSelected, setPropertyImageSelected] = useState(false);
  const { logOut } = useAuth();

  let _year = new Date();
  if (selector?.property?.yearBuild) {
    _year.setFullYear(selector?.property?.yearBuild);
  } else {
    _year.setFullYear(_year.getFullYear());
  }

  const formik = useFormik({
    initialValues: {
      fileUpload: selector?.property?.photo || '', // photo
      Bedrooms: selector?.property?.bed || '',
      Bathrooms: selector?.property?.bath || '',
      SqFt: selector?.property?.sqft || '',
      LotSize: selector?.property?.lotSize || '',
      YearBuilt: _year || '',
      PropertyPrice: selector?.property?.price || '',
      Description: selector?.property?.description || '',
      filePhoto: selector?.property.photo || ''
    },
    validate: (values) => {
      let errors: any = {};
      if (!values.fileUpload) {
        errors.fileUpload = 'This field is required';
      }
      if (!values.Bedrooms) {
        errors.Bedrooms = 'This field is required';
      }
      if (!values.Bathrooms) {
        errors.Bathrooms = 'This field is required';
      }
      if (!values.SqFt) {
        errors.SqFt = 'This field is required';
      }
      if (!values.LotSize) {
        errors.LotSize = 'This field is required';
      }
      if (!values.YearBuilt) {
        errors.YearBuilt = 'This field is required';
      }
      if (!values.PropertyPrice) {
        errors.PropertyPrice = 'This field is required';
      }
      // if (!values.Description) {
      //   errors.Description = 'This field is required';
      // }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Property.updatePropertyInfo) {
        let payload = {
          ...propertyModel?.property,
          templateId: 1,
          userTypeId: auth?.user?.userTypeId,
          id: propertyModel?.property?.id,
          propertyKey: 'p-8995',
          url: values.fileUpload,
          bed: values.Bedrooms,
          bath: values.Bathrooms,
          sqft: values.SqFt,
          lotSize: values.LotSize,
          price: values.PropertyPrice,
          yearBuild: new Date(values.YearBuilt || new Date()).getFullYear(),
          description: values.Description
        };

        if (hasPropertyImageSelected === true) {
          if (values.filePhoto) {
            payload['photo'] = values.filePhoto;
          }
        } else {
          delete payload.photo;
        }
        dispatch.Property.updatePropertyInfo(payload, (res: any) => {
          if (res?.statusCode === 401) {
            logOut(props.toast);
          } else {
            if (res.success) {
              navigate('/invite');
            } else {
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: res.message,
                life: 3000
              });
            }
          }
        });
        // resetForm();
      }
    }
  });

  const invoiceUploadHandler = ({ files }: any) => {
    if (files.length > 0) {
      setPropertyImageSelected(true);
      setFileURL(files[0].objectURL);
      formik.setFieldValue('fileUpload', files);
      uploadFileToS3(files[0]);
    }
  };

  const uploadFileToS3 = (file: any) => {
    const _key = `properties/temp/${moment().unix()}-${file.name}`;
    const params: any = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: _key
    };
    myBucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on('complete', (evt) => {
        console.log('~~~~ File Uploaded ~~~~', evt);
        formik.setFieldValue('filePhoto', _key);
      })
      .on('error', (evt) => {
        console.error('file upload error');
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const alertClick = () => {
    setPropertyImageSelected(false);

    setFileURL(null);
  };

  return (
    <div className="propertyInfoBox">
      <h1>Add Additional Detail</h1>
      <form>
        <div className="propertyInfoMain grid ">
          <div className="propertyUpload col-12 md:col-3">
            <div tabIndex={8}>
              <label>Add an Image</label>
              {fileURL ? (
                <div className="imgWrap">
                  <img src={fileURL} className="uploadImg" />
                  <div onClick={() => alertClick()} className="delUser imgDel">
                    <Trash size="22" color="#FFF" />
                  </div>
                </div>
              ) : (
                <FileUpload
                  name="invoice"
                  // onUpload={onBasicUpload}
                  accept=".jpg,.jpeg,.png"
                  customUpload={true}
                  uploadHandler={invoiceUploadHandler}
                  mode="basic"
                  auto={true}
                  chooseLabel="Upload Image"
                />
              )}
            </div>
            {formik.touched.fileUpload && formik.errors.fileUpload && (
              <small id="username2-help " className="p-error block pt-2 showError errorMessage">
                {'Your image size 12MB'}
              </small>
            )}
          </div>

          <div className="Propertyform  col-12 md:col-9">
            <div className="grid">
              {/* <div className="col-12 md:col-6 mb-5">
                <HSInput
                  label={'Numbers of Bedrooms'}
                  placeholder={'Numbers of Bedrooms'}
                  name="Bedrooms"
                  className="numberinput"
                  value={formik.values.Bedrooms}
                  tabIndex={2}
                  onChange={(e: any, v: any) => {
                    if (e.target.value.length === 0) {
                      formik.setFieldValue('Bedrooms', '');
                    } else {
                      const isError = customNumberFormate(e.target.value, 0, 99, 3);

                      isError && formik.setFieldValue('Bedrooms', e.target.value);
                    }
                  }}
                  isError={Boolean(formik.touched.Bedrooms && formik.errors.Bedrooms)}
                  errorMessage={formik.errors.Bedrooms}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <HSInput
                  label={'Numbers of Bathrooms'}
                  placeholder={'Numbers of Bathrooms'}
                  name="Bathrooms"
                  className="numberinput"
                  value={formik.values.Bathrooms}
                  tabIndex={3}
                  onChange={(e: any) => {
                    if (e.target.value.length === 0) {
                      formik.setFieldValue('Bathrooms', '');
                    } else {
                      const isError = customNumberFormate(e.target.value, 0, 99, 3);

                      isError && formik.setFieldValue('Bathrooms', e.target.value);
                    }
                  }}
                  isError={Boolean(formik.touched.Bathrooms && formik.errors.Bathrooms)}
                  errorMessage={formik.errors.Bathrooms}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <HSInput
                  label={'SqFt'}
                  placeholder={'SqFt'}
                  name="SqFt"
                  className="numberinput"
                  tabIndex={4}
                  value={formik.values.SqFt}
                  onChange={(e: any) => {
                    if (e.target.value.length === 0) {
                      formik.setFieldValue('SqFt', '');
                    } else {
                      const isError = customNumberFormate(e.target.value, 0, 999999, 3);

                      isError && formik.setFieldValue('SqFt', e.target.value);
                    }
                  }}
                  isError={Boolean(formik.touched.SqFt && formik.errors.SqFt)}
                  errorMessage={formik.errors.SqFt}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <HSInput
                  label={'Lot Size'}
                  placeholder={'Lot Size'}
                  name="LotSize"
                  className="numberinput"
                  value={formik.values.LotSize}
                  tabIndex={5}
                  onChange={(e: any) => {
                    if (e.target.value.length === 0) {
                      formik.setFieldValue('LotSize', '');
                    } else {
                      const isError = customNumberFormate(e.target.value, 0, 9999, 3);

                      isError && formik.setFieldValue('LotSize', e.target.value);
                    }
                  }}
                  isError={Boolean(formik.touched.LotSize && formik.errors.LotSize)}
                  errorMessage={formik.errors.LotSize}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <HSInput
                  label={'Year Built'}
                  placeholder={'Year Built'}
                  name="YearBuilt"
                  className="numberinput"
                  value={formik.values.YearBuilt}
                  tabIndex={6}
                  onChange={(e: any) => {
                    if (e.target.value.length === 0) {
                      formik.setFieldValue('YearBuilt', '');
                    } else {
                      if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 9999) {
                        formik.setFieldValue('YearBuilt', e.target.value);
                      }
                    }
                  }}
                  isError={Boolean(formik.touched.YearBuilt && formik.errors.YearBuilt)}
                  errorMessage={formik.errors.YearBuilt}
                  onBlur={formik.handleBlur}
                />
              </div> */}
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>Bedrooms</label>
                  <InputNumber
                    name="Bedrooms"
                    placeholder={'Bedrooms'}
                    value={formik.values.Bedrooms}
                    tabIndex={1}
                    onBlur={formik.handleBlur}
                    onValueChange={(e: any) => {
                      formik.setFieldValue('Bedrooms', e.target.value);
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('Bedrooms', e.value);
                    }}
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    mode="decimal"
                    min={0}
                    max={99}
                  />
                  {formik.touched['Bedrooms'] && formik.errors['Bedrooms'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['Bedrooms'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>Bathrooms</label>
                  <InputNumber
                    name="Bathrooms"
                    placeholder={'Bathrooms'}
                    value={formik.values.Bathrooms}
                    tabIndex={2}
                    onBlur={formik.handleBlur}
                    onValueChange={(e: any) => {
                      formik.setFieldValue('Bathrooms', e.target.value);
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('Bathrooms', e.value);
                    }}
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    mode="decimal"
                    min={0}
                    max={99}
                  />
                  {formik.touched['Bathrooms'] && formik.errors['Bathrooms'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['Bathrooms'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>SqFt</label>
                  <InputNumber
                    name="SqFt"
                    placeholder={'SqFt'}
                    value={formik.values.SqFt}
                    tabIndex={3}
                    onBlur={formik.handleBlur}
                    onValueChange={(e: any) => {
                      formik.setFieldValue('SqFt', e.target.value);
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('SqFt', e.value);
                    }}
                    minFractionDigits={0}
                    maxFractionDigits={2}
                    mode="decimal"
                    min={0}
                    max={99999}
                  />
                  {formik.touched['SqFt'] && formik.errors['SqFt'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['SqFt'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>Lot Size</label>
                  <InputNumber
                    name="LotSize"
                    placeholder={'LotSize'}
                    value={formik.values.LotSize}
                    tabIndex={4}
                    onBlur={formik.handleBlur}
                    onValueChange={(e: any) => {
                      formik.setFieldValue('LotSize', e.target.value);
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('LotSize', e.value);
                    }}
                    minFractionDigits={0}
                    maxFractionDigits={2}
                    mode="decimal"
                    min={0}
                    max={99999}
                  />
                  {formik.touched['LotSize'] && formik.errors['LotSize'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['LotSize'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>Year Build</label>
                  {/* <InputNumber
                  name="YearBuilt"
                  placeholder={'YearBuilt'}
                  value={formik.values.YearBuilt}
                  tabIndex={5}
                  onBlur={formik.handleBlur}
                  onValueChange={(e: any) => {
                    formik.setFieldValue('YearBuilt', e.target.value);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('YearBuilt', e.value);
                  }}
                  min={1900}
                  useGrouping={false}
                  max={new Date().getFullYear()}
                /> */}
                  <Calendar
                    id="YearBuilt"
                    minDate={new Date('1900')}
                    maxDate={new Date()}
                    tabIndex={5}
                    value={formik.values.YearBuilt}
                    onChange={(e) => formik.setFieldValue('YearBuilt', e.value)}
                    view="year"
                    dateFormat="yy"
                  />
                  {formik.touched['YearBuilt'] && formik.errors['YearBuilt'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['YearBuilt'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="inputBox">
                  <label>Property Price</label>
                  {/*<div className="p-inputgroup">
                   <span className="p-inputgroup-addon">$</span>
                  <HSInput
                    type={'number'}
                    placeholder={'Property Price'}
                    name="PropertyPrice"
                    className="numberinput"
                    tabIndex={7}
                    value={formik.values.PropertyPrice}
                    onChange={(e: any) => {
                      if (e.target.value.length === 0) {
                        formik.setFieldValue('PropertyPrice', '');
                      } else {
                        if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 99999999) {
                          formik.setFieldValue('PropertyPrice', e.target.value);
                        }
                      }
                    }}
                    isError={Boolean(formik.touched.PropertyPrice && formik.errors.PropertyPrice)}
                    errorMessage={formik.errors.PropertyPrice}
                    onBlur={formik.handleBlur}
                  />   
                  </div> */}
                  <InputNumber
                    name="PropertyPrice"
                    inputId="currency-us"
                    placeholder={'Property Price'}
                    value={formik.values.PropertyPrice}
                    tabIndex={6}
                    onBlur={formik.handleBlur}
                    onValueChange={(e: any) => {
                      formik.setFieldValue('PropertyPrice', e.target.value);
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('PropertyPrice', e.value);
                    }}
                    max={99999999}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                  {formik.touched['PropertyPrice'] && formik.errors['PropertyPrice'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['PropertyPrice'] as any}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12">
                {/* <HSTextarea
                  label={'DESCRIPION'}
                  placeholder={'Description'}
                  name="Description"
                  className="numberinput"
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  isError={Boolean(formik.touched.Description && formik.errors.Description)}
                  errorMessage={formik.errors.Description}
                  onBlur={formik.handleBlur}
                  tabIndex={8}
                /> */}
                <div className="textareaBox">
                  <label>Description</label>
                  <InputTextarea
                    name="Description"
                    placeholder={'Description'}
                    value={formik.values.Description}
                    tabIndex={7}
                    onBlur={(e: any) => {
                      formik.handleBlur('Description');
                      formik.setFieldValue('Description', e.target.value);
                    }}
                    // rows={5}
                    // cols={30}
                    onChange={(e: any) => {
                      formik.setFieldValue('Description', e.target.value);
                    }}
                  />
                  {formik.touched['Description'] && formik.errors['Description'] && (
                    <small
                      id="username2-help "
                      className="p-error block pt-2 showError errorMessage">
                      {formik.errors['Description'] as any}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="btnsWrap">
        {/* <div className="backBtn">Back</div> */}
        <div className="backBtn " onClick={() => navigate(-1)} tabIndex={11}>
          <ArrowLeft size={'20'} color={'#868C94'} />
          Back
        </div>
        <div>
          <HSButtonBlank label={'Skip'} onClick={() => navigate('/invite')} tabIndex={10} />
          <HSButton
            label={!loadingState.effects.Property.updatePropertyInfo ? 'Next' : 'Loading...'}
            type="submit"
            onClick={formik.handleSubmit}
            tabIndex={9}
          />
        </div>
      </div>
      {/* <HSButton label={'Confirm'} type="submit" /> */}
    </div>
  );
};
export default PropertyInfoBox;
