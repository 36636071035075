import APIHandler from '../APIHandler';
import { API } from '../../Common/API';

export default {
  createProperty: (payload, options) =>
    APIHandler.post(API.URL + API.Property.property, payload, options),
  updatePropertyInfo: (payload, options) =>
    APIHandler.put(API.URL + API.Property.property + '/' + payload.id, payload, options),
  fetchProperty: (payload, options) =>
    APIHandler.post(API.URL + API.Property.propertyDetails, payload, options),
  myTimeline: (payload, options) =>
    APIHandler.get(API.URL + API.Property.myTimeline, payload, options),
  createFullProperty: (payload, options) =>
    APIHandler.post(API.URL + API.Property.createFullProperty, payload, options)
};
