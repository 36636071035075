import React, { useEffect, useState } from 'react';
import { HSButton } from '../../stories/Button/Button';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HSInput, HSInputPassword } from '../../stories/Input/Input';
import { Link } from 'react-router-dom';
import { HSCheckbox } from '../../stories/Checkbox/Checkbox';
import './LoginBox.scss';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';

const LoginBox = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingState = useSelector((state: any) => state.loading);
  const selector = useSelector((state: any) => state.Auth);
  const [checked, setChecked] = useState('');
  const { logOut } = useAuth();

  useEffect(() => {
    dispatch.Property.clearPropertyProcess();
  }, []);

  useEffect(() => {
    if (selector.user?.id) navigate('/dashboard');
  }, []);
  const onClickForgetPassword = () => {
    const payload: any = {
      CognitoId: selector?.user?.CognitoId
    };
    dispatch.Auth.forgetPassword(payload, (resp: any) => {
      if (resp?.statusCode === 401) {
        logOut();
      } else {
        if (resp?.success) {
        } else {
          props.toast.current.show({
            severity: 'error',
            summary: 'Error Message',
            detail: resp.message,
            life: 3000
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
    }),

    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Auth.loginUser) {
        const payload: any = {
          email: values.email.toLowerCase(),
          password: values.password
        };
        dispatch.Auth.loginUser(payload, (resp: any) => {
          if (resp?.statusCode === 401) {
            logOut();
          } else {
            if (resp.success) {
              navigate('/dashboard');
            } else {
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: resp.message,
                life: 3000
              });
            }
          }
        });
      }
      // resetForm();
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="LoginBox">
        <h1>Login</h1>
        <div className="">
          <HSInput
            label={'Email'}
            placeholder={'Enter your email address'}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={Boolean(formik.touched.email && formik.errors.email)}
            errorMessage={formik.errors.email}
            onBlur={formik.handleBlur}
            tabIndex={1}
          />
        </div>
        <div className="HSInput">
          <HSInputPassword
            label={'Password'}
            name="password"
            placeholder={'Enter your password'}
            onChange={formik.handleChange}
            isError={Boolean(formik.touched.password && formik.errors.password)}
            errorMessage={formik.errors.password}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            tabIndex={2}
          />
        </div>
        <div className="flex justify-content-between HS-forgetText">
          <HSCheckbox
            inputId={'remember'}
            label={'Remember me'}
            checked={checked}
            onChange={(e: any) => setChecked(e.checked)}
            tabIndex={3}
          />
          {/* <a href={''} className="forgetText" onClick={onClickForgetPassword} >
            Forgot password?
          </a> */}
          <Link to={'/forgot-password'} className="forgetText" tabIndex={6}>
            Forgot password?
          </Link>
        </div>
        <HSButton
          label={!loadingState.effects.Auth.loginUser ? 'Login' : 'Loading...'}
          tabIndex={4}
          type="submit"
        />
        <div className="mt-5 Text">
          Not registered yet?
          <Link to={'/signup'} className="linkText" tabIndex={5}>
            Create an Account
          </Link>
        </div>
      </div>
    </form>
  );
};
export default LoginBox;
