import React from 'react';
import './MainBanner.scss';
const MainBanner = () => {
  return (
    <>
      <div className="mainBanner">
        <h1>My Properties</h1>
      </div>
    </>
  );
};

export default MainBanner;
