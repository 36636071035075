import React from 'react';
import './ImageView.scss';

const ImageView = () => {
  return (
    <>
      <div className="imgBox">
        <img src="/images/propertyImg.png" alt="" />
      </div>
    </>
  );
};

export default ImageView;
