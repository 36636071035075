import React, { useState } from 'react';
import { LocationIcon, LocationIconSm } from '../../assets/svg/Location';
import { HSInput } from '../../stories/Input/Input';
import { buildAddress } from '../../utils/Common/Constant';
import { OutsideClick } from '../../utils/Common/OutsideClick';
import './SmartyAddress.scss';

const SmartyAddress = (props: any) => {
  const { formik } = props;
  const [address, setAddress] = useState('');
  const [options, setOptions] = useState([]);

  function searchAddress(address: any) {
    address &&
      fetch(
        `${'https://us-autocomplete-pro.api.smartystreets.com/lookup'}?auth-id=${'142944530527563422'}&search=${address}&selected=&source=all&agent=${'smarty+(sdk:javascript@1.11.10)'}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.suggestions) {
            let responseData = data.suggestions;
            responseData.forEach((suggestion: any) => {
              let addressComp = buildAddress(suggestion, address);
              suggestion.fullAddress = addressComp[0];
              suggestion.fullAddressFormated = addressComp[1];
            });

            setOptions(responseData);
          } else {
            setOptions([]);
          }
        });
  }
  function searchSelectedAddress(item: any, selected: any) {
    fetch(
      `${'https://us-autocomplete-pro.api.smartystreets.com/lookup'}?auth-id=${'142944530527563422'}&search=${item}&selected=${selected}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data && data.suggestions) {
          let responseData = data.suggestions;
          responseData.forEach((suggestion: any) => {
            let addressComp = buildAddress(suggestion, address);
            suggestion.fullAddress = addressComp[0];
            suggestion.fullAddressFormated = addressComp[1];
          });

          setOptions(responseData);
        } else {
          setOptions([]);
        }
      });
  }
  return (
    <div className="smartyAddress">
      {/* <form onSubmit={formik.handleSubmit}> */}
      <div className="formGroup">
        <HSInput
          className="searchInput"
          placeholder="Search your property"
          name="fullAddress"
          type="text"
          label="Enter property address"
          onBlur={formik.handleBlur}
          tabIndex={1}
          onChange={(e: any) => {
            searchAddress(e.target.value);
            setAddress(e.target.value);
          }}
          value={address}
          isError={Boolean(formik.touched.fullAddress && formik.errors.fullAddress)}
          errorMessage={formik.errors.fullAddress}
        />
        <span className="locationIcon">
          <LocationIcon />
        </span>
        {options?.length > 0 && (
          <OutsideClick setOptions={setOptions}>
            <ul
              className="list"
              style={{
                height: `${options.length >= 10 ? '307px' : options.length * 32 + 'px'}`
              }}>
              {options.map((item: any, ind: any) => {
                return (
                  <li
                    key={ind}
                    className="listData"
                    onClick={() => {
                      if (item?.entries > 1) {
                        const addr = item?.fullAddress.split('(')[0];
                        const selectAddr = item?.fullAddress.split(' more entries');

                        setAddress(addr);
                        searchSelectedAddress(
                          addr,
                          `${selectAddr[0]}${selectAddr[1]} ${item?.zipcode}`
                        );
                      } else {
                        if (item) {
                          const add = `${item.street_line} ${item.secondary}, ${item.city}, ${item.state}`;
                          setAddress(item.fullAddress);
                          formik.setFieldValue('fullAddress', add);
                          formik.setFieldValue('street', `${item.street_line}, ${item.secondary}`);
                          formik.setFieldValue('city', item.city);
                          formik.setFieldValue('state', item.state);
                          formik.setFieldValue('zipCode', item.zipcode);
                          setOptions([]);
                        }
                      }
                    }}>
                    <span className="mapMarker">
                      <LocationIconSm />
                    </span>
                    {item.fullAddress}

                    {item.entries > 1 && <span className="downArrow"></span>}
                  </li>
                );
              })}
            </ul>
          </OutsideClick>
        )}
      </div>
      {/* </form> */}
    </div>
  );
};

export default SmartyAddress;
