import React, { useRef } from 'react';
import { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function logOut(toast) {
    // dispatch.Auth.logOutUser({}, (res) => {
    //   if (res?.success) {
    //     navigate('/');
    //   } else {
    //     toast.current.show({
    //       severity: 'error',
    //       summary: 'Error Message',
    //       detail: res.message,
    //       life: 3000
    //     });
    //   }
    // });
    navigate('/');
    dispatch({ type: 'RESET' });
  }
  return <AuthContext.Provider value={{ logOut }} {...props} />;
}

export default function useAuth() {
  return useContext(AuthContext);
}
