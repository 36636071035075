import React from 'react';
import { HSButtonBorder } from '../../../stories/Button/Button';
import './ButtonsView.scss';
import { Send2 } from 'iconsax-react';
import { Home2 } from 'iconsax-react';
const ButtonsView = () => {
  return (
    <>
      <div className="buttonBox">
        <HSButtonBorder label={'Invite People'}>
          <Send2 size="24" color="#FFF" />
        </HSButtonBorder>
        <HSButtonBorder label={'View Details'}>
          <Home2 size="24" color="#FFF" />
        </HSButtonBorder>
      </div>
    </>
  );
};

export default ButtonsView;
