import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Invitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // http://localhost:3000/invitation?ut=9&email=dev.homestretch+966@gmail.com&t=3&r=987&p=123
    let payload = {
      ut: searchParams.get('ut'),
      e: searchParams.get('email'),
      t: searchParams.get('t'),
      r: searchParams.get('r'),
      p: searchParams.get('p')
    };
    dispatch.Invitation.checkInvitation(payload, (res: any) => {
      if (res.success) {
        if (res?.data?.user && Object.keys(res?.data?.user).length > 0) {
          navigate('/');
        } else {
          navigate('/signup');
        }
      } else {
        navigate('/');
      }
    });
  }, []);

  return <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>;
};

export default Invitation;
