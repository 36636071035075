import React from 'react';
import { Card } from 'primereact/card';
import './CustomerReview.scss';
// import CustomerImage from '../../../public/images/customerImg.png'
const CustomerReview = () => {
  return (
    <div className="cardBox">
      <Card>
        Simply unbelievable, it made my task so easy to stay on top of my property purchase
        checklist
        <div className="customerInfo">
          <div className="customerImg">
            <img src="/images/customerImg.png" alt={''} />
          </div>
          <div className="customerText">
            <div className="name">Jonathan Edwards</div>
            <div className="designation">Property Buyer</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CustomerReview;
