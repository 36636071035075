import React from 'react';
import { HSButton } from '../../stories/Button/Button';
import { HSInputOtp } from '../../stories/Input/Input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './VerifyEmail.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { countDownTime } from '../../utils/Common/Constant';

const VerifyEmail = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => state.Auth);
  // let [searchParams, setSearchParams] = useSearchParams();
  const loadingState = useSelector((state: any) => state.loading);
  const InvitationModel = useSelector((state: any) => state.Invitation);
  const [email, setEmail] = useState(selector.user.email);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(countDownTime);
  const [hasError, setHasError] = useState(false);
  const { logOut } = useAuth();

  const changeHandler = (otp: any) => {
    setOtp(otp);
  };
  useEffect(() => {
    countDown(timer);
  }, []);

  const format = (time: any) => {
    let seconds: any = time % 60;
    let minutes: any = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    return minutes + ':' + seconds;
  };

  const countDown = (time: any) => {
    const _time = setInterval(() => {
      if (time > 0) time = time - 1;
      else {
        time = null;
        stop();
      }
      setTimer(time);
    }, 1000);
    const stop = () => {
      clearInterval(_time);
    };
  };

  const onClickResendCode = () => {
    const payload: any = {
      // cognitoID: selector.user.cognitoID
      email: selector?.user?.email
    };
    dispatch.Auth.reSendOtp(payload, (resp: any) => {
      if (resp?.statusCode === 401) {
        logOut();
      } else {
        if (resp?.success) {
          props.toast.current.show({
            severity: 'success',
            summary: 'Success Message',
            detail: resp.message,
            life: 3000
          });
        } else {
          props.toast.current.show({
            severity: 'error',
            summary: 'Error Message',
            detail: resp.message,
            life: 3000
          });
        }
      }
    });
    countDown(119);
  };

  // console.log(Object.keys(InvitationModel.invitation).length > 0, '*******Maulik********');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!loadingState.effects.Auth.verifyOtp) {
      if (otp.length < 6) {
        setHasError(true);
      } else {
        const payload = {
          email: selector?.user?.email,
          // password: selector?.user?.password,
          otp: otp
          // cognitoID: selector.user.cognitoID
        };
        dispatch.Auth.verifyOtp(payload, (res: any) => {
          if (res?.statusCode === 401) {
            logOut();
          } else {
            if (res.success) {
              if (Object.keys(InvitationModel.invitation).length > 0) {
                const payload = {
                  propertyId: parseInt(InvitationModel.invitation.p),
                  userTypeId: parseInt(InvitationModel.invitation.ut),
                  email: InvitationModel.invitation.e,
                  typeId: parseInt(InvitationModel.invitation.t),
                  roleId: parseInt(InvitationModel.invitation.r)
                };
                dispatch.Invitation.acceptInvitation(payload, (resp: any) => {
                  if (resp.success) navigate('/dashboard');
                });
              } else {
                // setSearchParams({email:selector?.user?.email})
                navigate('/property');
              }
              setOtp('');
            } else {
              props.toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: res.message,
                life: 3000
              });
            }
          }
        });

        setHasError(false);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="verifyEmail">
        <span className="headingText">Please enter the One-Time</span>
        <span className="headingText">Password to verify your account</span>
        <p className="verifyEmailPara">A One-Time Password has been sent to your email address</p>
        <div className="Text">
          <Link to={''} className="linkText text-decoration-none" tabIndex={4}>
            {email}
          </Link>
        </div>
        <HSInputOtp
          onChange={changeHandler}
          value={otp}
          name="otp"
          isError={hasError}
          tabIndex={1}
        />
        <HSButton
          label={!loadingState.effects.Auth.verifyOtp ? 'Confirm OTP' : 'Loading...'}
          type="submit"
          className="mb-5"
          tabIndex={2}
        />
        <div className="mt-3 Text flex align-items-center justify-content-center verifytext">
          {timer ? (
            <span className="confirmText disableLink ">Request a new verification code in </span>
          ) : (
            <span
              className="confirmText enableLink _pointer"
              onClick={() => onClickResendCode()}
              tabIndex={3}>
              Send new verification code
            </span>
          )}

          <span className=" confirmText linkText confirmCounter text-decoration-none">
            {format(timer) !== '00:00' && format(timer) !== '02:00' && format(timer)}
          </span>
        </div>
        <div></div>
      </div>
    </form>
  );
};

export default VerifyEmail;
