import { useEffect, useRef, useState } from 'react';
import { HSInput } from '../../stories/Input/Input';
import TimelineHeader from '../TimelineHeader/TimelineHeader';
import './index.scss';
import { HSButtonSecondary } from '../../stories/Button/Button';
import { arrColors } from './data';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { getDocId } from '../../utils/Common/Constant';
import { HSDropdown } from '../../stories/SelectDropdown/SelectDropdown';
import { Edit } from 'iconsax-react';
import { TickSquare } from 'iconsax-react';
import { Trash } from 'iconsax-react';

const index = (props: any) => {
  const navigate = useNavigate();
  const toast = useRef<any>(null);
  const timelineState = useSelector((state: any) => state.Timeline);
  const [timeline, setTimeline] = useState(timelineState.timeline);
  const [arrStages, setArrStages] = useState(timelineState.timeline.template.stages);
  const loadingState = useSelector((state: any) => state.loading);
  const propertyModel = useSelector((state: any) => state.Property);
  const dispatch = useDispatch();
  const { logOut } = useAuth();
  const [title, setTitle] = useState('');
  const arrAccess = ['No Access', 'Can View', 'Can Comment', 'Can Edit'];

  // useEffect(() => {
  //   setTimeline(timeline);
  // }, [timeline]);

  useEffect(() => {
    dispatch.Timeline.defaultTimeline({ id: propertyModel.property.id }, (resp: any) => {
      if (resp?.statusCode === 401) {
        logOut();
      } else {
        if (resp.success) {
          setTimeline(resp.data);
          setArrStages(resp.data.template.stages);
        } else {
          props.toast.current.show({
            severity: 'error',
            summary: 'Error Message',
            detail: resp.message,
            life: 3000
          });
        }
      }
    });
  }, []);

  const handleSubmitStage = () => {
    const _timeline = timeline;
    _timeline.template.stages = arrStages;
    _timeline.template.title = title;
    _timeline.propertyId = propertyModel.property.id;
    let hasError = false;
    arrStages.map((arr: any, index: any) => {
      arr.tasks.map((task: any, inx: any) => {
        if (task.internalPermission.length === 0) {
          hasError = true;
          task.internalError = true;
        }
        if (task.externalPermission.length === 0) {
          hasError = true;
          task.externalError = true;
        }
      });
    });
    setArrStages([...arrStages]);

    if (!loadingState?.Timeline?.updateTimeline && !hasError) {
      const payload: any = _timeline;
      dispatch.Timeline.updateTimeline(payload, (res: any) => {
        if (res?.statusCode === 401) {
          logOut();
        } else {
          if (res.success) {
            dispatch.Property.clearPropertyProcess();
            navigate('/dashboard');
          } else {
            props.toast.current.show({
              severity: 'error',
              summary: 'Error Message',
              detail: res.message,
              life: 3000
            });
          }
        }
      });
    }
  };

  return (
    <div className="taskBlock">
      <Toast ref={toast} />
      <TimelineHeader />
      <div className="wrapper ">
        <div className="titleWrap">
          <div className="grid justify-content-between align-items-center">
            <div className="col-12 md:col-6">
              <HSInput
                label={'Enter your timeline title'}
                placeholder={'Copy of default timeline template'}
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
                tabIndex={1}
              />
            </div>
            <div className="col-12 md:col-6">
              <div className="notificationBox">
                <span className="iconBox">
                  <i className="pi pi-info-circle"></i>
                </span>
                <span className="text">
                  You can modify bellow timeline template and add task for each user time according
                  to your need.
                </span>
              </div>
            </div>
          </div>
        </div>
        {arrStages?.length === 0 && (
          <div
            className="addNew"
            onClick={() => {
              let _arr: any = arrStages;
              _arr.splice(1, 0, {
                stageUUID: getDocId(),
                stage: '#Stage',
                stageColor: arrColors[Math.floor(Math.random() * 10)],
                tasks: [
                  {
                    taskUUID: getDocId(),
                    name: '#Task',
                    internalPermission: 'Can Edit',
                    externalPermission: 'Can Edit',
                    internalError: false,
                    externalError: false
                  }
                ],
                setUpCompleted: false
              });
              setArrStages([..._arr]);
            }}>
            + New Stage{' '}
          </div>
        )}
        {arrStages.map((ele: any, stageIndex: number) => (
          <div className="timelineTask" key={stageIndex}>
            <div className="timelineBox">
              <div className="timelineList headingText">
                <div className="timelineField">
                  <div className="timelineBtn">
                    {/* <div className="stageBtn" style={{ backgroundColor: ele.stageColor }}>
                      {ele.title}
                    </div> */}
                    <input
                      className="stageBtn"
                      value={ele.title}
                      style={{ backgroundColor: '#d6502b' }}
                      onChange={(e) => {
                        let _arr = arrStages;
                        _arr[stageIndex]['title'] = e.target.value;
                        setArrStages([..._arr]);
                      }}
                      tabIndex={2}
                    />
                    <div className="wrapBtns">
                      <div className="editBtn">
                        <Edit size="14" color="#FFF" />
                      </div>
                      <div className="divider"></div>
                      <div
                        className="deleteBtn"
                        onClick={() => {
                          let _arr = arrStages;
                          if (arrStages.length > 1) {
                            _arr.splice(stageIndex, 1);
                            setArrStages([..._arr]);
                          } else {
                            if (toast) {
                              toast!.current!.show({
                                severity: 'warn',
                                summary: 'Warn Message',
                                detail: "Sorry, You can't delete section",
                                life: 3000
                              });
                            }
                          }
                        }}>
                        <TickSquare size="14" color="#FFF" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timelineField">
                  <div className="titleText">
                    Internal Users{' '}
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.625 4.5H0.125L3.875 0.75L7.625 4.5Z" fill="#868C94"></path>
                      <path d="M0.125 9.5L7.625 9.5L3.875 13.25L0.125 9.5Z" fill="#868C94"></path>
                    </svg>
                  </div>
                </div>
                <div className="timelineField">
                  <div className="titleText">
                    External Users{' '}
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.625 4.5H0.125L3.875 0.75L7.625 4.5Z" fill="#868C94"></path>
                      <path d="M0.125 9.5L7.625 9.5L3.875 13.25L0.125 9.5Z" fill="#868C94"></path>
                    </svg>
                  </div>
                </div>
                <div className="timelineField"></div>
              </div>
              {ele?.tasks.map((task: any, taskIndex: number) => (
                <div className="timelineList" key={taskIndex}>
                  <div className="timelineField inputWrap">
                    <input
                      className="taskTitle"
                      name={'title'}
                      value={task.title}
                      onChange={(e) => {
                        let _arr = arrStages;
                        _arr[stageIndex]['tasks'][taskIndex]['title'] = e.target.value;
                        setArrStages([..._arr]);
                      }}
                      tabIndex={3}
                    />
                  </div>
                  <div className="timelineField">
                    <div className="field-checkbox">
                      <HSDropdown
                        onChange={(e: any) => {
                          let _arr = arrStages;
                          _arr[stageIndex]['tasks'][taskIndex]['internalPermission'] = e.value;
                          if (e.value) {
                            _arr[stageIndex]['tasks'][taskIndex]['internalError'] = false;
                          }
                          setArrStages([..._arr]);
                        }}
                        value={task.internalPermission}
                        name={'internalPermission'}
                        options={arrAccess}
                        id={'id2'}
                        tabIndex={4}
                        className={task.internalError && 'p-invalid'}
                      />
                    </div>
                  </div>
                  <div className="timelineField">
                    <HSDropdown
                      onChange={(e: any) => {
                        let _arr = arrStages;
                        _arr[stageIndex]['tasks'][taskIndex]['externalPermission'] = e.value;
                        if (e.value) {
                          _arr[stageIndex]['tasks'][taskIndex]['externalError'] = false;
                        }
                        setArrStages([..._arr]);
                      }}
                      value={task.externalPermission}
                      name={'externalPermission'}
                      options={arrAccess}
                      id={'id2'}
                      tabIndex={5}
                      className={task.externalError && 'p-invalid'}
                    />
                  </div>
                  <div className="timelineField">
                    <div className="wrapBtns">
                      <div className="editBtn">
                        <Edit size="22" color="#868C94" />
                      </div>
                      <div
                        className="deleteBtn"
                        onClick={() => {
                          let _arr = arrStages;
                          _arr[stageIndex]['tasks'].splice(taskIndex, 1);
                          setArrStages([..._arr]);
                        }}>
                        <Trash size="22" color="#868C94" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="iconSet">
              <div
                className="addNew"
                onClick={() => {
                  let _arr: any = arrStages;
                  _arr.splice(stageIndex + 1, 0, {
                    stageUUID: getDocId(),
                    title: '#Stage',
                    stageColor: arrColors[Math.floor(Math.random() * 10)],
                    tasks: [
                      {
                        taskUUID: getDocId(),
                        title: '#Task',
                        internalPermission: 'Can Edit',
                        externalPermission: 'Can Edit'
                      }
                    ],
                    setUpCompleted: false
                  });
                  setArrStages([..._arr]);
                }}>
                + New Stage{' '}
              </div>
              <div
                className="addUser"
                onClick={() => {
                  let _arr = arrStages;
                  _arr[stageIndex]['tasks'].push({
                    taskUUID: getDocId(),
                    title: '#task',
                    internalPermission: 'Can Edit',
                    externalPermission: 'Can Edit'
                  });
                  setArrStages([..._arr]);
                }}>
                + New Task{' '}
              </div>
            </div>
          </div>
        ))}
        <div className="completeBtn">
          <HSButtonSecondary
            label={!loadingState?.Timeline?.updateTimeline ? 'Complete Your Setup' : 'Loading...'}
            onClick={handleSubmitStage}
            tabIndex={5}
          />
        </div>
      </div>
    </div>
  );
};

export default index;
