import React, { useRef } from 'react';
import SignUpProgressSidebar from '../../components/SignUpProgressSidebar/SignUpProgressSidebar';
import PropertyInfoBox from '../../components/PropertyInfoBox/PropertyInfoBox';
import { Toast } from 'primereact/toast';

const PropertyInfo = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast} />
      <div className="wrap py-3">
        <div className="grid">
          <div className="col-12 md:col-6 xl:col-4">
            <SignUpProgressSidebar />
          </div>
          <div className="col-12 md:col-6 xl:col-8">
            <PropertyInfoBox toast={toast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
