import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './PropertyBox.scss';
import { useNavigate } from 'react-router-dom';
import { HSInput } from '../../stories/Input/Input';
import { HSButton, HSButtonBlank } from '../../stories/Button/Button';
import { OutsideClick } from '../../utils/Common/OutsideClick';
import { LocationIcon, LocationIconSm } from '../../assets/svg/Location';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { buildAddress } from '../../utils/Common/Constant';

const PropertyBox = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [address, setAddress] = useState('');
  const [options, setOptions] = useState([]);
  const selector = useSelector((state: any) => state.Property);
  const loadingState = useSelector((state: any) => state.loading);
  const auth = useSelector((state: any) => state.Auth);
  const propertyModel = useSelector((state: any) => state.Property);
  const { logOut } = useAuth();

  function searchAddress(address: any) {
    address &&
      fetch(
        `${'https://us-autocomplete-pro.api.smartystreets.com/lookup'}?auth-id=${'142944530527563422'}&search=${address}&selected=&source=all&agent=${'smarty+(sdk:javascript@1.11.10)'}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.suggestions) {
            let responseData = data.suggestions;
            responseData.forEach((suggestion: any) => {
              let addressComp = buildAddress(suggestion, address);
              suggestion.fullAddress = addressComp[0];
              suggestion.fullAddressFormated = addressComp[1];
            });

            setOptions(responseData);
          } else {
            setOptions([]);
          }
        });
  }
  function searchSelectedAddress(item: any, selected: any) {
    fetch(
      `${'https://us-autocomplete-pro.api.smartystreets.com/lookup'}?auth-id=${'142944530527563422'}&search=${item}&selected=${selected}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data && data.suggestions) {
          let responseData = data.suggestions;
          responseData.forEach((suggestion: any) => {
            let addressComp = buildAddress(suggestion, address);
            suggestion.fullAddress = addressComp[0];
            suggestion.fullAddressFormated = addressComp[1];
          });

          setOptions(responseData);
        } else {
          setOptions([]);
        }
      });
  }

  useEffect(() => {
    let fullAddress = `${selector?.property?.street || ''} ${selector?.property?.city || ''} ${
      selector?.property?.city ? ',' : ''
    } ${selector?.property?.state || ''}`.trim();
    setAddress(fullAddress);
  }, [selector]);

  // 12 1/2 2nd Ave NE, Ardmore, OK
  const formik = useFormik({
    initialValues: {
      fullAddress:
        // selector?.property?.fullAddress ||
        `${selector?.property?.street} ${selector?.property?.city}, ${selector?.property?.state}` ||
        '',
      street: selector?.property?.street || '',
      city: selector?.property?.city || '',
      state: selector?.property?.state || '',
      zipCode: selector?.property?.zipCode || ''
    },
    validationSchema: Yup.object({
      fullAddress: Yup.string().required('Please select address')
    }),
    onSubmit: (values, { resetForm }) => {
      if (!loadingState.effects.Property.createProperty) {
        const payload: any = {
          templateId: 1,
          userTypeId: auth?.user.userTypeId,
          propertyKey: values.fullAddress,
          street: values.street,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode
        };
        if (propertyModel?.property?.id) {
          payload.id = propertyModel?.property?.id;
          dispatch.Property.updatePropertyInfo(payload, (res: any) => {
            if (res?.statusCode === 401) {
              logOut(props.toast);
            } else {
              if (res.success) {
                navigate('/propertyinfo');
              } else {
                props.toast.current.show({
                  severity: 'error',
                  summary: 'Error Message',
                  detail: res.message,
                  life: 3000
                });
              }
            }
          });
        } else {
          dispatch.Property.createProperty(payload, (res: any) => {
            if (res?.statusCode === 401) {
              logOut(props.toast);
            } else {
              if (res.success) {
                navigate('/propertyinfo');
              } else {
                props.toast.current.show({
                  severity: 'error',
                  summary: 'Error Message',
                  detail: res.message,
                  life: 3000
                });
              }
            }
          });
        }
        // resetForm();
        // setAddress('');
      }
    }
  });

  console.log(formik);

  return (
    <div className="propertyBox">
      <h1 className="mainTitle">Add your first property</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="formGroup">
          <HSInput
            className="searchInput"
            placeholder="Search your property"
            name="fullAddress"
            type="text"
            label={'Enter property address'}
            onBlur={formik.handleBlur}
            tabIndex={1}
            onChange={(e: any) => {
              searchAddress(e.target.value);
              setAddress(e.target.value);
            }}
            value={address}
            isError={Boolean(formik.touched.fullAddress && formik.errors.fullAddress)}
            errorMessage={formik.errors.fullAddress}
          />
          <span className="locationIcon">
            <LocationIcon />
          </span>
          {options?.length > 0 && (
            <OutsideClick setOptions={setOptions}>
              <ul
                className="list"
                style={{
                  height: `${options.length >= 10 ? '307px' : options.length * 32 + 'px'}`
                }}>
                {options.map((item: any, ind: any) => {
                  return (
                    <li
                      key={ind}
                      className="listData"
                      onClick={() => {
                        if (item?.entries > 1) {
                          const addr = item?.fullAddress.split('(')[0];
                          const selectAddr = item?.fullAddress.split(' more entries');

                          setAddress(addr);
                          searchSelectedAddress(
                            addr,
                            `${selectAddr[0]}${selectAddr[1]} ${item?.zipcode}`
                          );
                        } else {
                          if (item) {
                            const add = `${item.street_line} ${item.secondary}, ${item.city}, ${item.state}`;
                            setAddress(item.fullAddress);
                            formik.setFieldValue('fullAddress', add);
                            formik.setFieldValue(
                              'street',
                              `${item.street_line}, ${item.secondary}`
                            );
                            formik.setFieldValue('city', item.city);
                            formik.setFieldValue('state', item.state);
                            formik.setFieldValue('zipcode', item.zipcode);
                            setOptions([]);
                          }
                        }
                      }}>
                      <span className="mapMarker">
                        <LocationIconSm />
                      </span>
                      {item.fullAddress}

                      {item.entries > 1 && <span className="downArrow"></span>}
                    </li>
                  );
                })}
              </ul>
            </OutsideClick>
          )}
        </div>
        <HSButton
          label={!loadingState.effects.Property.createProperty ? 'Confirm' : 'Loading...'}
          type="submit"
          className="addPropertyBtn"
          tabIndex={2}
        />
      </form>
    </div>
  );
};
export default PropertyBox;
