import React from 'react';
import DashboardBox from '../../components/DashboardBox/DashboardBox';

const Dashboard = () => {
  return (
    <div style={{ background: '#DEDDDE' }}>
      <DashboardBox />
    </div>
  );
};

export default Dashboard;
