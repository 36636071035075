import APIHandler from '../APIHandler';
import { API } from '../../Common/API';

export default {
  signUp: (payload) => APIHandler.post(API.URL + API.User.SignUp, payload),
  loginUser: (payload) => APIHandler.post(API.URL + API.User.loginUser, payload),
  VerifyOtp: (payload) => APIHandler.post(API.URL + API.User.verifyOtp, payload),
  reSendOtp: (payload) => APIHandler.post(API.URL + API.User.reSendOtp, payload),
  checkUserExistsInSystem: (payload) =>
    APIHandler.post(API.URL + API.User.checkUserExistsInSystem, payload),
  // checkVerifyOtp: (payload) => APIHandler.post(API.URL + API.User.checkVerifyOtp, payload),
  forgotPassword: (payload) => APIHandler.post(API.URL + API.User.forgotPassword, payload),
  verifyForgotPassword: (payload) =>
    APIHandler.post(API.URL + API.User.verifyForgotPassword, payload)
};
