import React from 'react';
import CompanyLogo from '../../assets/svg/CompanyLogo';
import CustomerReview from '../CardReview/CustomerReview.';
import './SignUpProgressSidebar.scss';
import { useLocation } from 'react-router-dom';

const SignUpProgressSidebar = () => {
  const location = useLocation();
  const progressData = [
    {
      title: 'Add your first Property',
      text: 'Add your property details 1',
      ind: 0,
      route: '/property'
    },
    {
      title: 'Add Additional Detail',
      text: 'Upload your property image',
      ind: 1,
      route: '/propertyinfo'
    },
    {
      title: 'Invite ',
      text: 'Add your property details',
      ind: 2,
      route: '/invite'
    },
    {
      title: 'Timeline',
      text: 'SetUp Timeline & Tasks',
      ind: 3,
      route: '/addtask'
    }
  ];
  let index: any = progressData?.find((ele) => ele?.route === location?.pathname)?.ind;
  if (index.ind > -1) {
    index = progressData[index]?.ind;
  }

  return (
    <div>
      <div className="companyProgressInfo">
        <div className="boxProgressWrap">
          <div className="logo-div">
            <a href="">
              <CompanyLogo />
            </a>
          </div>
          <div className="stepsBox">
            {progressData.map((item, inx) => {
              return (
                <div key={inx} className={`steps ${inx < index && 'complete'}`}>
                  <div className="status">
                    <span></span>
                  </div>
                  <div className="titleWrap">
                    <div className="progressTitle">
                      <h1 className="progressTitleHead">{item.title}</h1>
                    </div>
                    <p className="progresstext">{item.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* */}

          <CustomerReview />
        </div>
      </div>
    </div>
  );
};

export default SignUpProgressSidebar;
