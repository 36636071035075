import React from 'react';
import CompanyLogo from '../../assets/svg/CompanyLogo';
import './TimelineHeader.scss';
const TimelineHeader = () => {
  return (
    <>
      <div className="timlineHeader">
        <div className="logo">
          <CompanyLogo />
        </div>
      </div>
    </>
  );
};

export default TimelineHeader;
