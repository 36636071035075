import moment from 'moment';

export const customNumberFormate = (value: any, start: number, end: number, initLength: number) => {
  const valueCode = value.charCodeAt(value.length - 1);
  const num = parseFloat(value);
  let length = initLength;
  let isEnter = false;
  let allowDot = true;
  let secondDot = [...value];
  secondDot.pop();

  if (secondDot.indexOf('.') > 0) allowDot = false;
  else allowDot = true;

  //   if (value.length == 2 && parseFloat(value) == 0) {
  //     value = 0;
  //   }
  //   if (value.length == 3 && parseFloat(value) > 0) {
  //     length = initLength + 1;
  //   }

  if (
    ((valueCode >= 48 && valueCode <= 57) || (valueCode == 46 && allowDot)) &&
    num >= start &&
    num <= end
  ) {
    // if (num > Math.floor(end / 10)) length = initLength + String(end).length - 1;
    if (num > 9) length = initLength + 1;
    if (num > 99) length = initLength + 2;
    if (num > 999) length = initLength + 3;
    if (num > 9999) length = initLength + 4;
    if (num > 99999) length = initLength + 5;

    if (value.length <= length) isEnter = true;
  }

  return isEnter;
};

export const bearerToken = '';

export function onChaneEmail(txtInput: string) {
  const regex = /^[a-zA-Z0-9._@]*$/;
  if (regex.test(txtInput)) {
    return true;
  }
  return false;
}

export const isValidEmail = (email: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email)) {
    return false;
  }
  return true;
};
export const getDocId = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 25; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${result}${moment().valueOf() * 1000}`;
};

export function buildAddress(suggestion: any, search: any) {
  var whiteSpace = '';
  if (suggestion.secondary || suggestion.entries > 1) {
    if (suggestion.entries > 1) {
      suggestion.secondary += ' (' + suggestion.entries + ' more entries)';
    }
    whiteSpace = ' ';
  }
  var address =
    suggestion?.street_line +
    ', ' +
    suggestion.secondary +
    (suggestion.secondary ? ' ' : '') +
    suggestion?.city +
    ', ' +
    suggestion?.state;
  //  +
  // " " +
  // suggestion?.zipcode;

  var re = new RegExp(search, 'g');
  var res = address.replace(re, '<b>' + search + '</b>');
  return [address, res];
}

export const formatToUsCurrency = (num: string | number) => {
  return (
    '$' +
    Number(num)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

// export const formatToUsCurrency=(num: number) =>{
//   return Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD'
//   }).format(num);
// }

export const countDownTime = 120;
export const numOfPropertyInPage = 5;
