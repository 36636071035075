import React, { useRef } from 'react';
import LogoWhite from '../../assets/svg/LogoWhite';
import SearchIcon from '../../assets/svg/SearchIcon';
import { HSInput } from '../../stories/Input/Input';
import { Avatar } from 'primereact/avatar';
import { SearchNormal1 } from 'iconsax-react';
import useAuth from '../../hooks/useAuth';
import './index.scss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';

const MainHeader = () => {
  const { logOut } = useAuth();
  const nativate = useNavigate();
  const Auth = useSelector((state: any) => state.Auth);

  const menu = useRef<any>(null);

  const items = [
    {
      label: Auth.user.name.toUpperCase() || 'Welcome',

      items: [
        {
          label: 'Log Out',

          command: (e: any) => {
            logOut();
          }
        }
      ]
    }
  ];

  return (
    <>
      <div className="mainHeader">
        <div className="logo" onClick={() => nativate('/')}>
          <LogoWhite />
        </div>
        <div className="searchBox">
          <HSInput placeholder="Search property" />
          <div className="searchIcon">
            <SearchNormal1 size="24" color="#868C94" />
          </div>
        </div>
        <div className="profileDropdown">
          <Avatar
            label={Auth.user.name.substring(0, 1).toUpperCase()}
            className=""
            shape="circle"
            onClick={(event: any) => menu.current.toggle(event)}
          />
        </div>
        <Menu model={items} popup ref={menu} id="popup_menu" />
      </div>
    </>
  );
};

export default MainHeader;
